import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, } from "antd";
import {connect} from "react-redux";
import moment from "moment";
import VehiclePremiumForm from "../../../components/support/VehiclePremiumForm"
import PageBottom from "../../../components/layout/PageBottom";
import SearchArea from "../../../components/layout/SearchArea";
import LookupBrand from "../../wms/LookupBrand";
import LookupSeries from "../../wms/LookupSeries";
import LookupProduct from "../../wms/LookupProduct";
import {CustomizationConstant} from "../../Customization/config";
import {SupportSetting} from "../config";

const FormItem = Form.Item
const {RangePicker} = DatePicker;

function LookupVehicle(props) {

    //车牌号
    //plate String类型
    //客户id
    //customerId String类型
    //确认按钮
    //plate function (value)=>{}
    //是否多选
    //isMultiple false:单选  true:多选
    //canChangeCompany 选择公司
    // status 调用接口(/support/vehicle/list) 传的参数 状态：0.该搜索不启用 1.启用 2.禁用 3.无效数据
    let {
        plate, customerId, onOk, isMultiple
        , canChangeCompany = false
        , status = 0,
        isSelectAll = false
    } = props
    //表单方法
    const [form] = Form.useForm();

    let initialSearch = {
        plate: plate || "",
        vin: "",
        customerId: customerId || "",
        phone: "",
        name: "",
        buyDateStart: "",
        buyDateEnd: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        productName: "",
        productId: "",
        createdAtStart: "",
        createdAtEnd: "",
        selfCode: "",
        type: 0,
        status: status,
        ownerId: common.getUser().company.id || ""
    }
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])
    let [companies, setCompanies] = useState([])
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [vehicles, setVehicles] = useState([])//车辆列表
    let [total, setTotal] = useState(0)    //总记录条数
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false) //选择车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [vehicleFormVisible, setVehicleFormVisible] = useState(false) //新增车辆
    let [vehicleSourceTypeList, setVehicleSourceTypeList] = useState([]) // 车辆档案，车辆来源 有多个
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    // 获取公司列表
    let getCompanies = () => {
        if (canChangeCompany) {
            common.loadingStart()
            common.ajax('get', '/passport/company/all').then(data => {
                setCompanies(data)
            }).finally(common.loadingStop)
        }
    }
    useEffect(getCompanies, [])

    let getSupportSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/term/list', {
            taxonomy: params,
            status: SupportSetting.STATUS_YES,
            ownerId: common.getUser().company.id
        }).then(data => {
            setVehicleSourceTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 车辆档案，车辆来源
        getSupportSetting(SupportSetting.VEHICLE_SOURCE_TYPE)
    }, [])

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    //获取车信息
    let getVehicles = () => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
            customerId: customerId || "",
        }
        common.ajax("get", "/support/vehicle/list", params).then((res) => {
            setVehicles([...res.vehicles])
            setTotal(res.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getVehicles()
    }, [pagination, query, customerId])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //确定
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择车辆")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    const getAllData = () => {
        // 数量太大 会报错 
        let params = {
            ...query,
            ...pagination,
            customerId: customerId || "",
            page: 1,
            limit: total,
        }
        common.loadingStart();
        common.ajax('get', '/support/vehicle/list', params).then(data => {
            if (data && data.vehicles.length > 0) {
                onOk(data.vehicles)
            }
        }).finally(() => {
            common.loadingStop();
        })
    }

    const columns = [
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 160,
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '车架号/VIN',
            dataIndex: 'vin',
            key: 'vin',
            width: 160,
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '备用车架号/备用VIN',
            dataIndex: 'spareFrameNumber',
            key: 'spareFrameNumber',
            width: 160,
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            key: 'seriesName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '购买时间',
            dataIndex: 'buyDate',
            key: 'buyDate',
            width: 160,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '车主名称',
            dataIndex: 'name',
            key: 'name',
            width: 160,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 160,
            ellipsis: true,
        },
        {
            title: '车辆来源',
            dataIndex: 'typeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '自编号',
            dataIndex: 'selfCode',
            width: CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) ? 100 : 0,
            ellipsis: true,
        }
    ];

    return (
        <React.Fragment>
            <SearchArea>
                <Form form={form} className="ant-advanced-search-form label-character-3">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input type="text" placeholder='请输入车牌号'
                                    value={search.plate}
                                    onChange={(e) => {
                                        setSearch({...search, plate: e.target.value.trim()})
                                    }}
                                    onPressEnter={() => {
                                        setQuery(search)
                                        setPagination({...pagination, page: 1})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架号码"}>
                                <Input type="text" placeholder='请输入车架号'
                                    value={search.vin}
                                    onChange={(e) => {
                                        setSearch({...search, vin: e.target.value.trim()})
                                    }}
                                    onPressEnter={() => {
                                        setQuery(search)
                                        setPagination({...pagination, page: 1})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车主名称"}>
                                <Input type="text" placeholder='请输入车主名称'
                                    value={search.name}
                                    onChange={(e) => {
                                        setSearch({...search, name: e.target.value.trim()})
                                    }}
                                    onPressEnter={() => {
                                        setQuery(search)
                                        setPagination({...pagination, page: 1})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"联系电话"}>
                                <Input type="text" placeholder='请输入联系电话'
                                    value={search.phone}
                                    onChange={(e) => {
                                        setSearch({...search, phone: e.target.value.trim()})
                                    }}
                                    onPressEnter={() => {
                                        setQuery(search)
                                        setPagination({...pagination, page: 1})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车辆品牌"}>
                                <Input
                                    value={search.brandName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="品牌名称"
                                    suffix={
                                        search.brandName === "" ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandName: "", brandId: ""})
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车系名称"}>
                                <Input
                                    value={search.seriesName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="车系名称"
                                    suffix={
                                        search.seriesName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, seriesId: "", seriesName: ""})
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupSeriesVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupSeriesVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"车型名称"}>
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    value={search.productName}
                                    placeholder="车型名称"
                                    suffix={
                                        search.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search({...search, productId: "", productName: ""})
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"购车日期"}>
                                <RangePicker
                                    value={[search.buyDateStart === "" ? null : moment(search.buyDateStart),
                                    search.buyDateEnd === "" ? null : moment(search.buyDateEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                buyDateStart: "",
                                                buyDateEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                buyDateStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                buyDateEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"建档日期"}>
                                <RangePicker
                                    value={[search.createdAtStart === "" ? null : moment(search.createdAtStart),
                                    search.createdAtEnd === "" ? null : moment(search.createdAtEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                createdAtStart: "",
                                                createdAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                createdAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                createdAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        {
                            canChangeCompany &&
                            <Col span={6}>
                                <Form.Item label={'选择公司'}>
                                    <Select value={search.ownerId} onChange={value => {
                                        setSearch({...search, ownerId: value})
                                    }}>
                                        {
                                            companies.map(item => {
                                                return (<Select.Option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.nameShort}
                                                </Select.Option>)
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                            <Col span={6}>
                                <FormItem label={"自编号"}>
                                    <Input type="text"
                                        value={search.selfCode}
                                        onChange={(e) => {
                                            setSearch({...search, selfCode: e.target.value || ""})
                                        }}
                                    />
                                </FormItem>
                            </Col>
                        }
                        <Col span={6}>
                            <FormItem label="车辆来源">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    {vehicleSourceTypeList.map((item) => {
                                        return (<Select.Option value={parseInt(item.name)} key={parseInt(item.name)}>
                                            {item.spec}
                                        </Select.Option>)
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col
                            span={(canChangeCompany ? 6 : 12) + (CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) ? 0 : 6)}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    search = initialSearch
                                    setSearch({...search})
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                            } else {
                                rowSelection.onChange([record.id], [record], 1)
                            }
                        }, // 点击行
                    };
                }}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                dataSource={vehicles}
                columns={columns}
                scroll={{x: true}}
                pagination={false}
            />
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                <div>
                    <Button icon={<PlusOutlined />} onClick={() => {
                        setVehicleFormVisible(true)
                    }}>新增车辆</Button>
                </div>
                <div>
                    {
                        vehicles.length > 0 ? (
                            <PageBottom pagination={
                                <div style={{display: 'flex'}}>
                                    {isSelectAll && total && <a style={{lineHeight: '28px', marginRight: '10px'}}
                                        onClick={getAllData}>选择全部{total}条</a>}
                                    <Pagination onChange={handlePageChange}
                                        onShowSizeChange={handlePageChange}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        defaultCurrent={pagination.page}
                                        defaultPageSize={pagination.limit}
                                        current={pagination.page}
                                        pageSizeOptions={['10', '20', '40', '80']}
                                        showQuickJumper={true}
                                        showSizeChanger
                                    />
                                </div>
                            }>
                            </PageBottom>
                        ) : ''
                    }
                </div>
            </div>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

            <Modal
                visible={vehicleFormVisible}
                footer={null}
                width={1000}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => setVehicleFormVisible(false)}>
                <br />
                <VehiclePremiumForm
                    onSave={() => {
                        setVehicleFormVisible(false)
                        getVehicles()
                    }} />
            </Modal>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }} />

                </Modal>
            </div>

            <div>
                <Modal title="选车系"
                    destroyOnClose={true}
                    visible={lookupSeriesVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupSeriesVisible(false)
                    }}>
                    <LookupSeries
                        isMultiple={false}
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }} />
                </Modal>
            </div>

            <div>
                <Modal title="选车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }} />
                </Modal>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LookupVehicle)
