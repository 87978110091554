import React, {useEffect, useState} from 'react';
import {CloseOutlined, DoubleRightOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Basic, Brand, Product, Receipt, Warehouse} from "../../../../components/wms/config";
import SkuStock from "./skuStock";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import LookupAll from "../../../../components/passport/LookupAll";
import ProductGoodsReceiptAndDelivery from "../../../../components/wms/ProductGoodsReceiptAndDelivery";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import moment from "moment";
import condition from "../../../../utils/condition";
import ExportButton from "../../../../components/export";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [subtotal, setSubtotal] = useState({});
    let [brand, setBrand] = useState([]);// 品牌

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        receiptCode: "",
        type: "",
        number: "",
        warehouseId: "",
        productId: "",
        deliveryNumber: "",
        brandIds: brand.map(item => item.id),
        kind: action,
        ownerId: common.getUser().company.id,
        isHaveGoodsInStock: Basic.IS_ANSWER_YES,

        // 接口中没有的字段
        exampleWarehouseList: [],
        exampleSupplier: "",
        exampleName: "",
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [number, setNumber] = useState('')
    let [rows, setRows] = useState([])

    const columns = [
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => {
                return <a
                    onClick={() => {
                        setNumber(text)
                        setModalTitle(action === Product.KIND_PART ? "配件入出库明细账" : "精品入出库明细账")
                        setModalNumber(3)
                        setVisible2(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '仓位',
            width: 100,
            align: 'center',
            dataIndex: 'positionName',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '数量',
            width: 100,
            align: 'right',
            dataIndex: 'salableQuantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '单位',
            align: 'center',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '金额',
            width: 150,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '税率',
            width: 100,
            align: 'right',
            dataIndex: 'taxPercent',
            ellipsis: true,
            // render: (text) => text === undefined ? '' : new BigNumber(text).multipliedBy(100).toString() + "%"
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'priceTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库类型',
            align: 'center',
            width: 100,
            dataIndex: ['receiptDto', 'type'],
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
            )
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '送货单号',
            width: 150,
            dataIndex: ['receiptDto', 'deliveryNumber'],
            ellipsis: true
        },
        {
            title: '库龄段',
            width: 150,
            dataIndex: 'time',
            ellipsis: true
        },
        {
            title: '库龄/天',
            width: 100,
            dataIndex: 'day',
            ellipsis: true
        },
        {
            title: '采购人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '辅助供货商',
            width: 200,
            dataIndex: 'supplierIdAuxiliaryName',
            ellipsis: true
        },
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            if (data.receiptItems.length > 0) {
                setSubtotal(data.subtotal2)

                let objMy = {
                    id: "-1",
                    salableQuantity: 0,
                    priceConst: 0,
                    priceGo: 0,
                    priceTax: 0,
                    receiptDate: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    salableQuantity: data.subtotal2.quantity,
                    priceConst: data.subtotal2.price,
                    priceGo: data.subtotal2.taxGo,
                    priceTax: data.subtotal2.priceTax,
                    receiptDate: '合计',
                }
                data.receiptItems.forEach((item) => {
                    // 金额
                    item.priceConst = item.total
                    // 去税金额
                    item.priceGo = item.totalGo
                    // 税金
                    item.priceTax = item.totalTax

                    // 小计
                    // 数量
                    objMy.salableQuantity = new BigNumber(item.salableQuantity).plus(objMy.salableQuantity).toString()
                    // 金额
                    objMy.priceConst = new BigNumber(item.priceConst).plus(objMy.priceConst).toString()
                    // 去税金额
                    objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toString()
                })
                objMy.priceTax = new BigNumber(objMy.priceConst).minus(objMy.priceGo).toNumber()
                data.receiptItems.push(objMy)
                data.receiptItems.push(objMy2)
            } else {
                setSubtotal({})
            }
            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (Array.isArray(sorter.field) && sorter.field[0] === 'productDto') {
            sorter.field = 'product.`' + sorter.field[1] + '`'
        } else if (sorter.field === 'priceConst') {
            sorter.field = 'receiptItem.`salable_quantity`*receiptItem.`price`'
        } else {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    let rowSelection = {
        selectedRowKeys: rows.map(item => item.id),
        hideSelectAll: true,
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (selected) {
                setRows([
                    ...rows,
                    record
                ])
            } else {
                setRows(rows.filter(item => item.id !== record.id))
            }
        },
        getCheckboxProps: record => ({
            disabled: record.id === '-1' || record.id === '-2'
        }),
    }

    // 移库
    let updateSkuStock = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/productGoods/stock", params).then(() => {
            common.toast("移库成功")
            getData()
            setRows([])
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品批次库存" : "配件批次库存"}>
                <Button
                    icon={<DoubleRightOutlined />}
                    disabled={rows.length === 0}
                    onClick={() => {
                        let arr = rows.map(item => item.receiptId)

                        if (new Set(arr).size > 1) {
                            common.toast("请选择同一批入库单进行操作")
                            return false
                        }
                        setVisible(true)
                    }}>移库</Button>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? "精品批次库存.xlsx" : '配件批次库存.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        receiptCode: query.receiptCode,
                        type: query.type,
                        number: query.number,
                        warehouseId: query.warehouseId,
                        productId: query.productId,
                        deliveryNumber: query.deliveryNumber,
                        kind: query.kind,
                        ownerId: query.ownerId,
                        isHaveGoodsInStock: query.isHaveGoodsInStock,
                        brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                    })}
                    type={action === Product.KIND_ARTICLE ? 'wms_boutique_sku_receipt' : 'wms_part_sku_receipt'}
                />
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        setRows([])
                                    }}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setVisible2(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="入库单号">
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        setRows([])
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.type} onChange={(val) => {
                                    // setSearch({...search, type: val, types: []})
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                        key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                        key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                        key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                        key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                        key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                        key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}</Option>
                                    <Option value={initialSearch.type}
                                        key={initialSearch.type}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="库别名称">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.exampleWarehouseList}
                                    onChange={(val) => {
                                        setSearch({...search, exampleWarehouseList: val, warehouseId: val.toString()})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={search.exampleSupplier}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSupplier === '' ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, supplierId: '', exampleSupplier: ''})
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="送货单号">
                                <Input value={search.deliveryNumber} onChange={(e) => {
                                    setSearch({...search, deliveryNumber: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        setRows([])
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({
                                                ...search,
                                                brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                            })
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Select.Option key={''} value={''}>全部</Select.Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    setRows([])
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setRows([])
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/sku/receipt'} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === '-1' || record.id === '-2') {
                                return false
                            }

                            rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical" />
                        库存金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical" />
                        去税金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical" />
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <SkuStock action={action} defaultValue={rows} onOk={updateSkuStock} />
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        isHaltProduction={'all'}
                        action={'all'}
                        defaultValue={action}
                        isMultiple={false}
                        onOk={(val) => {
                            setSearch({...search, productId: val.id, exampleName: val.name})
                            setVisible2(false)
                        }} />}
                {modalNumber === 3 &&
                    <ProductGoodsReceiptAndDelivery action={action} number={number} />}
                {modalNumber === 2 &&
                    <LookupAll
                        isPartner={true}
                        isCompany={true}
                        isMultiple={false}
                        onOk={(val) => {
                            setSearch({...search, supplierId: val.id, exampleSupplier: val.name})
                            setVisible2(false)
                        }} />}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)