import React, {useEffect, useRef, useState} from 'react';
import {PlusOutlined, PrinterOutlined, CheckSquareOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, Tabs, Tag, Tooltip, DatePicker} from 'antd';
import BigNumber from "bignumber.js";
import PageTop from '../../../../components/layout/PageTop';
import Search from "../../../../utils/search";
import common from '../../../../utils/common';
import LookupProductStockNumber from '../../../../components/wms/LookupProductStockNumber';
import LookupPlan from '../../../../components/support/LookupPlan';
import moment from 'moment';


function FakeRepairEdit(props) {
    //  工单类型
    const REPAIR_TYPE_NORMAL = "1" // 普通工单
    const REPAIR_TYPE_DELEGATE = "2" // 内协工单
    const repairTypeAlias = {
        [REPAIR_TYPE_NORMAL]: '普通工单',
        [REPAIR_TYPE_DELEGATE]: '内协工单'
    }
    let [businessKinds, setBusinessKinds] = useState([]) // 业务类别
    let [businessKindAlias, setBusinessKindAlias] = useState({})
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [repairCategoriesAlias, setRepairCategoriesAlias] = useState({})
    let [sections, setSections] = useState([]) // 工段
    const [form] = Form.useForm();
    const [repair, setRepair] = useState({})
    const [projectList, setProjectList] = useState([])
    const [productList, setProductList] = useState([])
    const [projectPlanVisible, setProjectPlanVisible] = useState(false)
    const [productVisible, setProductVisible] = useState(false)
    const [activePan, setActivePan] = useState('1')

    const onInit = async () => {
        common.loadingStart()
        let res = await common.ajax('get', '/support/repair/findById?id=' + props.repairId).finally(() => {
            common.loadingStop()
        })
        if (res) {
            res.lastMileage = ""
            if (res.repairItems) {
                setProjectList(res.repairItems.filter(item => item.type == 1))
                setProductList(res.repairItems.filter(item => item.type == 2))
            }
            if (res.vin) {
                let data = await common.ajax('get', '/support/repair/getLastMileage', {ownerId: common.getUser().company.id, vin: res.vin})
                if (data !== null && data !== undefined) {
                    res.lastMileage = data
                }
            }
        }
        setRepair(res)
        form.setFieldsValue(res)
    }
    // 过滤预估材料并组装数据
    useEffect(() => {
        if (props.repairId) {
            onInit()
        }
    }, [props.repairId])

    useEffect(() => {
        if (props.repairData && props.repairData.data) {
            if (props.repairData.data.repairItems) {
                setProjectList(props.repairData.data.repairItems.filter(item => item.type == 1))
                setProductList(props.repairData.data.repairItems.filter(item => item.type == 2))
            }
            setRepair(props.repairData.data)
            form.setFieldsValue(props.repairData.data)
        }
    }, [props.repairData])

    let onFinish = (vals, cb) => {
        common.confirm(`是否确认${cb ? '保存并打印' : '保存'}估价单?`, () => {
            let data = {
                ...repair,
                ...vals,
                repairItems: [...projectList, ...productList],
                projectTotal: projectList.reduce((total, item) => {
                    return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                }, 0).toFixed(2),
                productReceiptTotal: productList.reduce((total, item) => {
                    return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                }, 0).toFixed(2),
                discountTotal: new BigNumber(0)
                    .plus(projectList.reduce((total, item) => {
                        return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                    }, 0))
                    .plus(productList.reduce((total, item) => {
                        return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                    }, 0))
                    .toFixed(2),
                total: new BigNumber(projectList.reduce((total, item) => {
                    return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                }, 0))
                    .plus(productList.reduce((total, item) => {
                        return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                    }, 0))
                    .toFixed(2)
            }
            if (props.repairId) {
                // 新增
                if (data.checkStatus == undefined) {
                    data.checkStatus = 0
                }
                let params = {
                    businessId: repair.id,
                    ownerId: common.getUser().company.id,
                    spec: '',
                    data
                }
                common.loadingStart()
                common.ajax('post', '/support/print/create', params)
                    .then((data) => {
                        if (cb) {
                            cb()
                        } else if (props.onOk) {
                            props.onOk()
                        }
                    })
                    .finally(() => {
                        common.loadingStop()
                    })
            } else if (props.repairData) {
                // 编辑
                let params = {
                    id: props.repairData.id,
                    spec: '',
                    data
                }
                common.loadingStart()
                common.ajax('post', '/support/print/updateById', params)
                    .then((data) => {
                        if (cb) {
                            cb()
                        } else if (props.onOk) {
                            props.onOk()
                        }
                    })
                    .finally(() => {
                        common.loadingStop()
                    })

            }

        })
    }
    // 获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
            userIds: [common.getUser().company.id],
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                setSections(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: 1,
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
                res.businessKinds.forEach(item => {
                    businessKindAlias[item.id] = item.chargeType
                })
                setBusinessKindAlias(businessKindAlias)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    // 获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory', status: 1})
            .then((res) => {
                let repairCategories = res || []
                setRepairCategories([...repairCategories])
                // 工单类型 默认业务类别
                let repairCategoryObj = {}
                for (let repairCategory of repairCategories) {
                    repairCategoryObj[repairCategory.id] = repairCategory.parentId
                }
                setRepairCategoriesAlias(repairCategoryObj)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getSections()
        getBusinessKinds()
        getRepairCategories()
    }, [])

    // 处理模态框选中预估材料信息
    let handlePredictProductDataSource = (value) => {
        setProductVisible(false)
        let predictProducts = [...productList]
        // 删除重复的预估材料
        for (let i = 0;i < predictProducts.length;i++) {
            value = value.filter(item => item.number !== predictProducts[i].number)
        }
        for (let i = 0;i < value.length;i++) {
            value[i].productId = value[i].id
            value[i].productName = value[i].name
            value[i].productDto = {number: value[i].number}
            value[i].spec = ""
            value[i].quantity = "1.00"
            value[i].salePrice = value[i].priceOne
            value[i].price = value[i].priceOne
            value[i].total = value[i].priceOne * value[i].quantity
            value[i].type = '2'
            value[i].businessKind = ""
            // 根据工单类型 默认业务类型
            if (repair.repairCategoryId !== undefined && repair.repairCategoryId !== null && repair.repairCategoryId !== "") {
                value[i].businessKind = repairCategoriesAlias[repair.repairCategoryId]
            }
            predictProducts.push(value[i])
        }
        setProductList([...predictProducts])
    }

    // 处理模态框选中工时项目/维修套餐
    let handleRepairPlanDataSource = (result) => {
        setProjectPlanVisible(false)
        // 重置工时项目的字段命名
        result.projects.forEach(item => {
            item.projectId = item.id
            item.projectName = item.name
            item.sectionId = item.section
            item.total = item.price
            item.salePrice = item.price
            item.quantity = 1
            item.businessKind = item.businessKind || ''
            // 根据工单类型 默认业务类型
            if (item.businessKind === "" && repair.repairCategoryId !== undefined && repair.repairCategoryId !== null && repair.repairCategoryId !== "") {
                item.businessKind = repairCategoriesAlias[repair.repairCategoryId]
            }
            item.type = '1' // 设置业务类型
            sections.forEach(section => {
                if (section.id === item.sectionId) {
                    item.sectionName = section.name
                }
            })
        })

        // 重置预估材料的字段命名
        result.products.forEach(item => {
            item.productName = item.name
            // 维修套餐
            if (item.productDto == null) {
                item.productDto = {number: item.number}
                item.salePrice = item.price
                item.productId = item.id
            }
            item.type = '2' // 设置业务类型
        })
        // 去重维修项目
        let projects = [...projectList, ...result.projects]
        //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
        let obj1 = {};
        projects = projects.reduce((item, next) => {
            if (!obj1[next.id]) {
                item.push(next);
                obj1[next.id] = true;
            }
            return item;
        }, []);
        setProjectList([...projects])
        // 去重预估材料
        let products = [...productList, ...result.products]
        //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
        let obj = {};
        products = products.reduce((item, next) => {
            if (!obj[next.id]) {
                item.push(next);
                obj[next.id] = true;
            }
            return item;
        }, []);
        setProductList([...products])
    }

    return (
        <React.Fragment>
            <PageTop title={'估价单'}>
                {/* <Button onClick={() => {}}>选择公司</Button> */}
                {
                    props.onBack && <Button icon={<RollbackOutlined />} onClick={() => {
                        props.onBack()
                    }}>返回
                    </Button>
                }
                <Button onClick={() => {
                    onFinish(form.getFieldsValue(), (data) => {
                        // TODO data 用于打印
                        common.consoleLog('打印数据:', data)
                    })
                }}>打印</Button>
                <Button onClick={() => {
                    form.submit()
                }}>保存</Button>
                <Button onClick={() => {
                    common.confirm("是否确认核对?", () => {
                        setRepair({
                            ...repair,
                            checkAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                            checkBy: common.getUser().nickname,
                            checkStatus: 1
                        })
                    })
                }}>核对确认</Button>
            </PageTop>
            <Form form={form} onFinish={onFinish} initialValues={{}}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"客户名称"} name="customerName">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"送修人员"} name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"联系电话"} name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"车主名称"} name="vehicleName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"车牌号码"} name="plate">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"进厂里程"} name="mileage">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"维修车系"} name="seriesName">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"车架号码"} name="vin">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"工单编号"} name="code">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"服务顾问"} name="employeeName">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"结算人员"} name="clearCreator">
                            <Input />
                        </Form.Item>
                        <Form.Item label={"工单类型"} name="type">
                            <Select >
                                {
                                    Object.keys(repairTypeAlias).map((key) => {
                                        return <Select.Option value={key} key={key}>{repairTypeAlias[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"报修日期"} >
                            <DatePicker value={repair.makeAt ? moment(repair.makeAt) : null}
                                onChange={(date, dateString) => {
                                    setRepair({...repair, makeAt: dateString})
                                }} format='YYYY-MM-DD HH:mm:ss' />
                        </Form.Item>
                        <Form.Item label={"派工日期"} >
                            <DatePicker value={repair.dispatchAt ? moment(repair.dispatchAt) : null}
                                onChange={(date, dateString) => {
                                    setRepair({...repair, dispatchAt: dateString})
                                }} format='YYYY-MM-DD HH:mm:ss' />
                        </Form.Item>
                        <Form.Item label={"竣工日期"} >
                            <DatePicker value={repair.finishAt ? moment(repair.finishAt) : null}
                                onChange={(date, dateString) => {
                                    setRepair({...repair, finishAt: dateString})
                                }} format='YYYY-MM-DD HH:mm:ss' />
                        </Form.Item>
                        <Form.Item label={"结算日期"} >
                            <DatePicker value={repair.settmentAt ? moment(repair.settmentAt) : null}
                                onChange={(date, dateString) => {
                                    setRepair({...repair, settmentAt: dateString})
                                }} format='YYYY-MM-DD HH:mm:ss' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"发动机号"} name="engineNum">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"预结备注"} name="specPay">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"单据备注"} name="spec">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Tabs defaultActiveKey="1" onChange={e => {
                setActivePan(e)
            }} tabBarExtraContent={{
                right: <Button icon={<PlusOutlined />} size='mini' type="primary" onClick={() => {
                    activePan === '1' ? setProjectPlanVisible(true) : setProductVisible(true)
                }}>{activePan === '1' ? '新增工时' : '新增材料'}</Button>
            }}>
                <Tabs.TabPane tab="工时项目" key="1"  >
                    <Table
                        rowKey={record => record.id}
                        columns={[
                            {
                                title: '序号',
                                dataIndex: 'key',
                                key: 'key',
                                width: 60,
                                ellipsis: true,
                                render: (text, record, index) => {
                                    if (record.summary) {
                                        return "合计"
                                    } else {
                                        return index + 1
                                    }
                                }
                            },
                            {
                                title: '项目名称',
                                dataIndex: 'projectName',
                                key: 'projectName',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? <Input
                                        style={{width: 100}}
                                        value={record.projectName}
                                        onChange={e => {
                                            let pro = [...projectList]
                                            pro[index].projectName = e.target.value
                                            setProjectList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '维修工段',
                                dataIndex: 'sectionName',
                                key: 'sectionName',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '业务类别',
                                dataIndex: 'businessKind',
                                key: 'businessKind',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <Select style={{width: 100}} value={record.businessKind}
                                            onChange={(value) => {
                                                projectList.forEach(item => {
                                                    if (item.id === record.id) {
                                                        item.businessKind = value
                                                        item.chargeType = businessKindAlias[value]
                                                    }
                                                })
                                                setProjectList([...projectList])
                                            }}>
                                            {businessKinds.map(item => (
                                                <Select.Option value={item.id} key={item.id}> {item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    ) : null
                                }
                            },
                            {
                                title: '',
                                dataIndex: 'businessKind',
                                key: 'businessKind',
                                ellipsis: true,
                                width: 80,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <Button icon={<CheckSquareOutlined />}
                                            hidden={!(index === 0 && record.businessKind !== "")}
                                            onClick={() => {
                                                projectList.forEach(item => {
                                                    item.chargeType = record.chargeType
                                                    item.businessKind = record.businessKind
                                                })
                                                setProjectList([...projectList])
                                            }}>批量
                                        </Button>
                                    ) : null
                                }
                            },
                            {
                                title: '工时费',
                                dataIndex: 'salePrice',
                                key: 'salePrice',
                                ellipsis: true,
                                width: 120,
                                align: 'right',
                                render: (text, record, index) => {
                                    if (record.summary) {
                                        let total = projectList.reduce((total, item) => {
                                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                                        }, 0)
                                        return new BigNumber(total).toFixed(2)
                                    } else {
                                        return text
                                    }
                                }
                            },
                            {
                                title: '优惠/优惠金额',
                                dataIndex: 'discount',
                                key: 'discount',
                                ellipsis: true,
                                width: 120,
                                align: 'right',
                                render: (text, record, index) => {
                                    return !record.summary ? <InputNumber
                                        style={{width: 100}}
                                        value={record.discount}
                                        precision={2}
                                        min={0}
                                        onChange={value => {
                                            let pro = [...projectList]
                                            pro[index].discount = value
                                            setProjectList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '优惠后金额',
                                dataIndex: 'discountPrice',
                                key: 'discountPrice',
                                ellipsis: true,
                                width: 120,
                                render: ((text, record) => {
                                    if (record.summary) {
                                        let total = projectList.reduce((total, item) => {
                                            let SaleTotal = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).toString(), 2)
                                            let discountPrice = new BigNumber(SaleTotal).minus(item.discount || 0)
                                            return total + discountPrice.toNumber()
                                        }, 0)
                                        return new BigNumber(total).toFixed(2)
                                    } else {
                                        let SaleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                                        let discountPrice = new BigNumber(SaleTotal).minus(record.discount || 0).toFixed(2)
                                        return discountPrice
                                    }
                                })
                            },
                            {
                                title: '操作',
                                key: 'operation',
                                align: 'center',
                                fixed: 'right',
                                ellipsis: true,
                                width: 100,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <div className={"action-btns"}>
                                            <a onClick={() => {
                                                let pro = [...projectList]
                                                pro.splice(index, 1)
                                                setProjectList(pro)
                                            }}>删除</a>
                                        </div>
                                    ) : null
                                }
                            }
                        ]}
                        dataSource={[...projectList, {summary: true}]}
                        scroll={{x: '100%'}}
                        pagination={false}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="材料费用" key="2">
                    <Table
                        rowKey={record => record.id}
                        columns={[
                            {
                                title: '序号',
                                dataIndex: 'key',
                                key: 'key',
                                width: 60,
                                ellipsis: true,
                                render: (text, record, index) => {
                                    if (record.summary) {
                                        return "合计"
                                    } else {
                                        return index + 1
                                    }
                                }
                            },
                            {
                                title: '配件编号',
                                dataIndex: ['productDto', 'number'],
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? <Input
                                        style={{width: 100}}
                                        value={record.productDto.number}
                                        onChange={e => {
                                            let pro = [...productList]
                                            pro[index].productDto.number = e.target.value
                                            setProductList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '材料名称',
                                dataIndex: 'productName',
                                key: 'productName',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? <Input
                                        style={{width: 100}}
                                        value={record.productName}
                                        onChange={e => {
                                            let pro = [...productList]
                                            pro[index].productName = e.target.value
                                            setProductList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '数量',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? <InputNumber
                                        style={{width: 100}}
                                        value={record.quantity}
                                        precision={2}
                                        min={0}
                                        onChange={value => {
                                            let pro = [...productList]
                                            pro[index].quantity = value
                                            setProductList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            // {
                            //     title: '出库单价',
                            //     dataIndex: 'deliveryPrice',
                            //     width: 80,
                            //     align: "right",
                            //     render: (text, record) => {
                            //         return record.summary ? '' : common.numberFormat(text)
                            //     }
                            // },
                            // {
                            //     title: '出库总价',
                            //     width: 80,
                            //     align: "right",
                            //     render: (text, record) => {
                            //         if (record.summary) {
                            //             let total = productList.reduce((total, item) => {
                            //                 return total + new BigNumber(item.deliveryPrice).multipliedBy(item.quantity).toNumber()
                            //             }, 0)
                            //             return new BigNumber(total).toFixed(2)
                            //         } else {
                            //             let deliverTotal = common.numberHalfUp(new BigNumber(record.deliveryPrice).multipliedBy(record.quantity).toString(), 2)
                            //             return common.numberFormat(deliverTotal)
                            //         }
                            //     }
                            // },
                            {
                                title: '销售单价',
                                dataIndex: 'salePrice',
                                width: 120,
                                align: "right",
                                ellipsis: true,
                                render: (text, record, index) => {
                                    return !record.summary ? <InputNumber
                                        style={{width: 100}}
                                        value={record.salePrice}
                                        precision={2}
                                        min={0}
                                        onChange={value => {
                                            let pro = [...productList]
                                            pro[index].salePrice = value
                                            setProductList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '总金额',
                                width: 80,
                                align: "right",
                                ellipsis: true,
                                render: ((text, record) => {
                                    if (record.summary) {
                                        let total = productList.reduce((total, item) => {
                                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                                        }, 0)
                                        return new BigNumber(total).toFixed(2)
                                    } else {
                                        let SaleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                                        return common.numberFormat(SaleTotal)
                                    }

                                })
                            },
                            {
                                title: '业务类别',
                                dataIndex: 'businessKind',
                                key: 'businessKind',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <Select style={{width: 100}} value={record.businessKind}
                                            onChange={(value) => {
                                                productList.forEach(item => {
                                                    if (item.id === record.id) {
                                                        item.businessKind = value
                                                        item.chargeType = businessKindAlias[value]
                                                    }
                                                })
                                                setProductList([...productList])
                                            }}>
                                            {businessKinds.map(item => (
                                                <Select.Option value={item.id} key={item.id}> {item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    ) : null
                                }
                            },
                            {
                                title: '',
                                dataIndex: 'businessKind',
                                key: 'businessKind',
                                ellipsis: true,
                                width: 80,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <Button icon={<CheckSquareOutlined />}
                                            hidden={!(index === 0 && record.businessKind !== "")}
                                            onClick={() => {
                                                productList.forEach(item => {
                                                    item.chargeType = record.chargeType
                                                    item.businessKind = record.businessKind
                                                })
                                                setProductList([...productList])
                                            }}>批量
                                        </Button>
                                    ) : null
                                }
                            },
                            {
                                title: '优惠/优惠金额',
                                dataIndex: 'discount',
                                key: 'discount',
                                align: 'right',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    return !record.summary ? <InputNumber
                                        style={{width: 100}}
                                        value={record.discount}
                                        precision={2}
                                        min={0}
                                        onChange={value => {
                                            let pro = [...productList]
                                            pro[index].discount = value
                                            setProductList(pro)
                                        }}
                                    /> : null
                                }
                            },
                            {
                                title: '优惠后金额',
                                dataIndex: 'discountPrice',
                                key: 'discountPrice',
                                ellipsis: true,
                                width: 120,
                                render: ((text, record) => {
                                    if (record.summary) {
                                        let total = productList.reduce((total, item) => {
                                            let SaleTotal = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).toString(), 2)
                                            let discountPrice = new BigNumber(SaleTotal).minus(item.discount || 0)
                                            return total + discountPrice.toNumber()
                                        }, 0)
                                        return new BigNumber(total).toFixed(2)
                                    } else {
                                        let SaleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                                        let discountPrice = new BigNumber(SaleTotal).minus(record.discount || 0).toFixed(2)
                                        return discountPrice
                                    }
                                })
                            },
                            {
                                title: '操作',
                                key: 'operation',
                                align: 'center',
                                fixed: 'right',
                                ellipsis: true,
                                width: 100,
                                render: (text, record, index) => {
                                    return !record.summary ? (
                                        <div className={"action-btns"}>
                                            <a onClick={() => {
                                                let pro = [...productList]
                                                pro.splice(index, 1)
                                                setProductList(pro)
                                            }}>删除</a>
                                        </div>
                                    ) : null
                                }
                            }
                        ]}
                        dataSource={[...productList, {summary: true}]}
                        scroll={{x: '100%'}}
                        pagination={false}
                    />
                </Tabs.TabPane>
            </Tabs>

            <div style={{fontWeight: 800, lineHeight: '30px', marginTop: '20px', padding: '0 20px'}}>
                <Row gutter={24}>
                    <Col span={8}>
                        优惠前工时：{projectList.reduce((total, item) => {
                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                        }, 0).toFixed(2)}
                    </Col>
                    <Col span={8}>
                        优惠前材料：{productList.reduce((total, item) => {
                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                        }, 0).toFixed(2)}
                    </Col>
                    <Col span={8}>
                        总费用：{new BigNumber(projectList.reduce((total, item) => {
                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                        }, 0)).plus(productList.reduce((total, item) => {
                            return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                        }, 0)).toFixed(2)}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        工时优惠：{
                            projectList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0).toFixed(2)
                        }
                    </Col>
                    <Col span={8}>
                        材料优惠：{
                            productList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0).toFixed(2)
                        }
                    </Col>
                    <Col span={8}>
                        已优惠：{
                            new BigNumber(projectList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0)).plus(productList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0)).toFixed(2)
                        }
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        优惠后工时：{
                            new BigNumber(projectList.reduce((total, item) => {
                                return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                            }, 0)).minus(projectList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0)).toFixed(2)
                        }
                    </Col>
                    <Col span={8}>
                        优惠后材料：{
                            new BigNumber(productList.reduce((total, item) => {
                                return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                            }, 0)).minus(productList.reduce((total, item) => {
                                return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                            }, 0)).toFixed(2)
                        }
                    </Col>
                    <Col span={8}>
                        客户应付：{
                            new BigNumber(projectList.reduce((total, item) => {
                                return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                            }, 0))
                                .minus(projectList.reduce((total, item) => {
                                    return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                                }, 0))
                                .plus(productList.reduce((total, item) => {
                                    return total + new BigNumber(item.salePrice).multipliedBy(item.quantity).toNumber()
                                }, 0))
                                .minus(productList.reduce((total, item) => {
                                    return total + new BigNumber(item.discount || 0).multipliedBy(item.quantity).toNumber()
                                }, 0))
                                .toFixed(2)
                        }
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={8}>
                        是否核对：{repair.checkStatus == 1 ? '是' : '否'}
                    </Col>
                    <Col span={8}>
                        核对人：{repair.checkStatus == 1 && repair.checkBy}
                    </Col>
                    <Col span={8}>
                        核对时间：{repair.checkStatus == 1 && repair.checkAt}
                    </Col>
                </Row>
            </div>

            <Modal title="选择工时项目或套餐"
                visible={projectPlanVisible}
                width={1000}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setProjectPlanVisible(false)
                }}>
                <LookupPlan
                    canSelectStatus={false}
                    status={1}
                    isMultiple={true}
                    onOk={handleRepairPlanDataSource}
                />
            </Modal>
            <Modal title="选配件或者精品"
                visible={productVisible}
                width={1000}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setProductVisible(false)
                }}>
                <LookupProductStockNumber
                    isMultiple={true}
                    defaultValue={'part'}
                    onOk={handlePredictProductDataSource}
                />
            </Modal>
        </React.Fragment>
    )
}

export default FakeRepairEdit