import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, Row, Form, Divider, Modal, Select} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common"
import {Basic, Brand, Product, Warehouse} from "../../../../components/wms/config";
import ProductGoodsReceiptAndDelivery from "../../../../components/wms/ProductGoodsReceiptAndDelivery";
import BigNumber from "bignumber.js";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import condition from "../../../../utils/condition";
import ExportButton from "../../../../components/export";

// 配件/精品 实物库存
function InventoryIndex(props) {
    // action 默认 part(配件) | boutique(精品)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'salableQuantity DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({});
    let [brand, setBrand] = useState([])// 品牌

    // 搜索条件
    let initialSearch = {
        name: "",
        number: "",
        barCode: "",
        warehouseId: "",
        ownerId: common.getUser().company.id,
        kind: action,
        brandIds: brand.map(item => item.id),
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [visible, setVisible] = useState(false)
    let [number, setNumber] = useState('')
    let [warehouse, setWarehouse] = useState([]) // 仓库

    const columnsPart = [
        {
            title: '配件编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '条码编号',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '最新进货价',
            width: 150,
            align: 'right',
            dataIndex: 'lastReceiptPrice',
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '账面数',
            width: 100,
            align: 'right',
            dataIndex: 'salableQuantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '索赔价',
            dataIndex: 'priceThree',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '保险价',
            dataIndex: 'priceTwo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '虚入库数量',
            width: 100,
            align: 'right',
            dataIndex: 'emptyQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '虚入库到货数量',
            width: 100,
            align: 'right',
            dataIndex: 'emptyArrivedQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '未发放实物数量',
            width: 100,
            align: 'right',
            dataIndex: 'deliveryNotGrantQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实物库存小计',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.receiptQuantity).plus(record.deliveryNotGrantQuantity).toString())
        },
    ]
    const columnsBoutique = [
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '条码编号',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '最新进货价',
            width: 150,
            align: 'right',
            dataIndex: 'lastReceiptPrice',
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '账面数',
            width: 100,
            align: 'right',
            dataIndex: 'salableQuantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: '精品价',
            dataIndex: 'priceFour',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '虚入库数量',
            width: 100,
            align: 'right',
            dataIndex: 'emptyQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '虚入库到货数量',
            width: 100,
            align: 'right',
            dataIndex: 'emptyArrivedQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '未发放实物数量',
            width: 100,
            align: 'right',
            dataIndex: 'deliveryNotGrantQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实物库存小计',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.receiptQuantity).plus(record.deliveryNotGrantQuantity).toNumber())
        },
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            isAll: Basic.IS_ANSWER_YES,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/sku/inventory", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.list.length > 0) {
                let obj = {
                    id: '-1',
                    number: '小计',
                    salableQuantity: 0,
                    emptyQuantity: 0,
                    emptyArrivedQuantity: 0,
                    receiptQuantity: 0,
                    deliveryNotGrantQuantity: 0,
                }
                let obj2 = {
                    id: '-2',
                    number: '合计',
                    salableQuantity: data.subtotal.salableQuantity,
                    emptyQuantity: data.subtotal.emptyQuantity,
                    emptyArrivedQuantity: data.subtotal.emptyArrivedQuantity,
                    receiptQuantity: new BigNumber(data.subtotal.receiptQuantity).plus(data.subtotal.deliveryNotGrantQuantity).toString(),
                    deliveryNotGrantQuantity: data.subtotal.deliveryNotGrantQuantity,
                }
                data.list.forEach((item) => {
                    obj.salableQuantity = new BigNumber(item.salableQuantity).plus(obj.salableQuantity).toString()
                    obj.emptyQuantity = new BigNumber(item.emptyQuantity).plus(obj.emptyQuantity).toString()
                    obj.emptyArrivedQuantity = new BigNumber(item.emptyArrivedQuantity).plus(obj.emptyArrivedQuantity).toString()
                    obj.receiptQuantity = new BigNumber(item.receiptQuantity).plus(obj.receiptQuantity).toString()
                    obj.deliveryNotGrantQuantity = new BigNumber(item.deliveryNotGrantQuantity).plus(obj.deliveryNotGrantQuantity).toString()
                })

                data.list.push(obj)
                data.list.push(obj2)
            }
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    let handleTableChange = (page, filters, sorter) => {
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品实物库存" : "配件实物库存"}>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? "精品实物库存.xlsx" : '配件实物库存.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        ...query,
                        brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                    })}
                    type={action === Product.KIND_ARTICLE ? 'wms_boutique_inventory' : 'wms_part_inventory'}
                />
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="条码编号">
                                <Input value={search.barCode} onChange={(e) => {
                                    setSearch({...search, barCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="仓库名称">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Select.Option value={item.id}
                                                key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({...search, brandIds: val})
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Select.Option key={''} value={''}>全部</Select.Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/inventory'} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={action === Product.KIND_ARTICLE ? columnsBoutique : columnsPart}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}} />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        账面数: {common.numberFormat(subtotal.salableQuantity)}
                        <Divider type="vertical" />
                        虚入库数量: {common.numberFormat(subtotal.emptyQuantity)}
                        <Divider type="vertical" />
                        虚入库到货数量: {common.numberFormat(subtotal.emptyArrivedQuantity)}
                        <Divider type="vertical" />
                        未发放实物数量: {common.numberFormat(subtotal.deliveryNotGrantQuantity)}
                        <Divider type="vertical" />
                        实际库存数: {common.numberFormat(new BigNumber(subtotal.receiptQuantity).plus(subtotal.deliveryNotGrantQuantity).toString())}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            {Object.keys(subtotal).length > 0 &&
                <div>实物库存=(账面-虚入库+虚入库到货+未发实物登记-借料登记+借料归还)</div>}

            <Modal
                maskClosable={false}
                visible={visible}
                title={action === Product.KIND_PART ? "配件入出库明细账" : "精品入出库明细账"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <ProductGoodsReceiptAndDelivery action={action} number={number} />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryIndex)