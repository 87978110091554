import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Input, InputNumber, Modal, Popconfirm, Row, Table, Form, Tooltip, DatePicker, Divider} from "antd";
import common from "../../../../utils/common"
import {
    CloseOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
    CheckSquareOutlined
} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import {useHistory} from "react-router-dom";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import {
    Basic, Delivery,
    Product,
    ProductCategoryRate,
    ProductGeneralRate,
    Receipt, Term, wmsPublic
} from "../../../../components/wms/config";
import GoodsReceiptItem from "../../../../components/wms/LookupGoodsReceiptItem";
import moment from "moment";
import BigNumber from "bignumber.js";
import Search from "../../../../utils/search";

const {TextArea} = Input

function ReceiveForm(props) {
    // action 字符串 配件/精品 必填 part(配件) article(精品)  commodity(商品 实际上就是精品 只是不显示成本价)
    let {action, numbers} = props

    let [list, setList] = useState([]) // 表格数据
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [employee, setEmployee] = useState({});//领料人
    let [quantity, setQuantity] = useState(0);
    let [total, setTotal] = useState(0);
    let [setting, setSetting] = useState({})
    let [taxonomy, setTaxonomy] = useState({})

    let companyId = common.getUser().company.id

    let history = useHistory()

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/listForDelivery", {
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
            brandId: common.getUser().brand.id,
            kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            ownerId: common.getUser().company.id,
            number: params,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            isHaveGoodsInStock: Basic.IS_ANSWER_YES,//要有库存
            chargeType: action === Product.KIND_PART ? ProductGeneralRate.CHARGE_TYPE_SALE : ProductGeneralRate.CHARGE_TYPE_BOUTIQUE,
            orderType: ProductCategoryRate.ORDER_TYPE_RECEIVE,
            limit: action === 'commodity' ? 5000 : 10,
        }).then((data) => {
            if (action === 'commodity') {
                let numberArr = numbers.split(",")
                let pickArr = []
                for (let number of numberArr) {
                    for (let receiptItem of data.receiptItems) {
                        if (number === receiptItem.productDto.number) {
                            pickArr.push(receiptItem)
                            break;
                        }
                    }
                    productGoodsOnOk(pickArr)
                }
            } else {
                if (data.receiptItems.length === 1) {
                    let arr = list.filter(item => item.id === data.receiptItems[0].id)
                    if (arr.length > 0) {
                        productGoodsOnOk(list)
                    } else {
                        productGoodsOnOk([...list, ...data.receiptItems])
                    }
                    setTimeout(() => {
                        emptyInput.current.focus()
                    }, 100)
                } else {
                    setModalNumber(1)
                    setModalTitle(action === Product.KIND_PART ? '配件选择' : action === Product.KIND_ARTICLE ? '精品选择' : '商品选择')
                    setVisible(true)
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 默认选中库存
    useEffect(() => {
        if (numbers !== undefined && numbers !== null && numbers !== "" && numbers.length > 0) {
            getData(numbers)
        }
    }, [numbers])

    const columns = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : action === Product.KIND_PART ? '配件编号' : '商品编号',
            dataIndex: ['productDto', 'number'],
            width: 180,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle(action === Product.KIND_ARTICLE ? '精品选择' : action === Product.KIND_PART ? '配件选择' : '商品选择')
                                    setVisible(true)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : action === Product.KIND_PART ? '配件名称' : '商品名称',
            width: 180,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined || text === null) {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined /></Tooltip>
                    </div>
                )
            }
        },
        {
            title: action === 'commodity' ? '库存数' : '可出库数量',
            dataIndex: 'salableQuantity',
            width: 80,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        // defaultValue={text}
                        value={text}
                        onChange={(val) => {
                            // list[index].quantity = val
                            let sl = [...list]
                            sl[index].quantity = val
                            setList(sl)
                        }}
                        onBlur={() => {
                            // 左下角 出库数量 出库金额
                            let number = 0;
                            let price = 0;
                            list.forEach((item) => {
                                if (item.quantity !== null) {
                                    number = new BigNumber(item.quantity).plus(number).toNumber()
                                }

                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                }
                            })
                            setQuantity(number)
                            setTotal(price)
                        }}
                    />
                )
            }
        },
        {
            title: '',
            width: action === Product.KIND_PART ? 80 : 0,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    action === Product.KIND_PART && <Button icon={<CheckSquareOutlined />}
                        hidden={!(index === 0)}
                        onClick={() => {
                            let number = 0;
                            let price = 0;
                            setList(list.map(item => {
                                item.quantity = item.salableQuantity
                                if (item.quantity !== null) {
                                    number = new BigNumber(item.quantity).plus(number).toNumber()
                                }

                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                }
                                return item
                            }))
                            setQuantity(number)
                            setTotal(price)
                        }}>批量
                    </Button>
                )
            }
        },
        {
            title: '单位',
            width: 70,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: action === 'commodity' ? 0 : 80,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: <Tooltip title={"默认为配件档案管理中的计划售价"}>出库价 <QuestionCircleOutlined /></Tooltip>,
            dataIndex: 'price',
            width: action === 'commodity' ? 0 : 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        min={0}
                        precision={2}
                        defaultValue={text}
                        disabled={(Object.keys(taxonomy).length > 0 && taxonomy.flag === Term.SETTING_NO)}
                        onChange={(val) => {
                            list[index].price = val
                        }}
                        onBlur={(val) => {
                            // 出库总金额
                            let price = 0;
                            list.forEach((item) => {
                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                }
                            })
                            setTotal(price)
                        }}
                    />)
            }
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 100,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 120,
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 100,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }} />)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => {
                                    let arr = list.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList(arr)

                                    // 左下角 出库数量 出库金额
                                    let number = 0;
                                    let price = 0;
                                    arr.forEach((item) => {
                                        if (item.quantity !== null) {
                                            number = new BigNumber(item.quantity).plus(number).toNumber()
                                        }

                                        if (item.quantity !== null && item.price !== null) {
                                            price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                        }
                                    })
                                    setQuantity(number)
                                    setTotal(price)
                                }}
                                okText="确定"
                                cancelText="取消">
                                <Button type={'link'}>删除</Button>
                            </Popconfirm>}
                    </div>)
            }
        }
    ]

    // 精品/配件 选择
    let productGoodsOnOk = (val) => {
        let arr = []
        val.forEach((item1) => {
            let number = 0
            list.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)
                }
            })
            if (number === 0) {
                arr.push({
                    ...item1,
                    price: item1.priceCost,
                    quantity: 1,
                    spec: ''
                })
            }
        })

        let quantity = 0
        let price = 0
        arr.forEach((item) => {
            quantity = new BigNumber(quantity).plus(item.quantity).toString()
            price = new BigNumber(item.price).multipliedBy(item.quantity).plus(price).toString()
        })
        // 价格 数量 左下角
        setQuantity(quantity)
        setTotal(price)

        setList(arr)
        setVisible(false)
        setNewValue('')
    }

    const [form] = Form.useForm()

    let onFinish = (values) => {
        let params = {
            ...values,
            ownerId: companyId,
            customerId: employee.id,
            employeeId: employee.id,
            groupId: "",
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
            kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            grantItIs: action === Product.KIND_PART ? Delivery.DELIVERY_ITEM_STATUS_SHIPPED : Delivery.DELIVERY_ITEM_STATUS_UNSHIPPED,
            deliveryItem: list.map((item) => {
                return {
                    productId: item.productId,
                    receiptItemId: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    spec: item.spec,
                }
            })
        }
        common.loadingStart();
        common.ajax("post", "/wms/productGoods/delivery/receive", params).then((data) => {
            common.toast("创建成功")
            if (action === Product.KIND_PART) {
                history.push("/wms/part/delivery/receive")
            } else if (action === Product.KIND_ARTICLE) {
                history.push("/wms/article/delivery/receive")
            } else {
                history.push("/wms/commodity/delivery/receive")
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取配置数据
    let getSettingData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res != null) {
                    if (Term.GOODS_DELIVERY_RECEIVE_SELECT_DATE === taxonomy) {
                        setSetting(res)
                    } else if (Term.COMMODITY_RECEIVE_DELIVERY_EMPLOYEE === taxonomy) {
                        if (res.spec !== null && res.spec.length > 0) {
                            setEmployee({id: res.spec, name: res.employeeName})
                            form.setFieldsValue({employeeName: res.employeeName})
                        }
                    } else {
                        setTaxonomy(res)
                    }
                } else {
                    // 默认不允许修改
                    setTaxonomy({
                        flag: Term.SETTING_NO
                    })
                }
            })
            .finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()
        getSettingData(Term.GOODS_DELIVERY_RECEIVE_SELECT_DATE)
        if (action === Product.KIND_PART) {
            getSettingData(Term.PART_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE)
        } else {
            getSettingData(Term.BOUTIQUE_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE)
        }
        if (action === 'commodity') {
            getSettingData(Term.COMMODITY_RECEIVE_DELIVERY_EMPLOYEE)
        }
    }, [])

    return (
        <>
            <PageTop
                title={action === Product.KIND_PART ? "配件领用出库" : action === Product.KIND_ARTICLE ? '精品领用出库' : '商品领用出库'}>
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    onClick={() => {
                        if (Object.keys(employee).length === 0) {
                            common.alert("请选择领料人")
                            return false
                        }

                        if (list.length === 0) {
                            common.alert(action === Product.KIND_PART ? '请选择配件' : action === Product.KIND_ARTICLE ? '请选择精品' : '请选择商品')
                            return false
                        }
                        for (let item of list) {
                            if (item.quantity === null) {
                                common.alert("请填写出库数量")
                                return false
                            }
                            if (item.price === null) {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }

                        let num = 0;
                        list.forEach((item) => {
                            if (item.quantity > item.salableQuantity) {
                                num++;
                            }
                        })
                        if (num !== 0) {
                            common.alert(action === 'commodity' ? '出库数量不允许大于库存数量' : "出库数量不允许大于可出库数量")
                            return false
                        }
                        form.submit()
                    }}>保存</Button>
                <Button
                    icon={<RollbackOutlined />}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>
            <Form
                initialValues={{
                    spec: '',
                    creatorName: common.getUser().nickname,
                }}
                form={form}
                className={'label-character-4'}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'employeeName'}
                            rules={[{required: true, message: '领料人不允许为空'}]}
                            label="领料人">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    employee.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployee({})
                                                form.setFieldsValue({employeeName: ""})
                                            }} /> : <span />
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(3)
                                            setModalTitle("选择领料人")
                                            setVisible(true)
                                        }} />
                                }
                                onKeyPress={() => {
                                    setModalNumber(3)
                                    setModalTitle("选择领料人")
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                style={{width: '100%'}}
                                disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'creatorName'}
                            label="制单人">
                            <Input
                                style={{width: '100%'}}
                                disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="出库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={[...list, {
                    isEmptyRecord: true,
                    id: -1
                }]}
                scroll={{x: '100%', y: "500px"}} />
            <div style={{marginTop: '10px'}}>
                配件名称: {list.length}
                <Divider type="vertical" />
                出库数量: {common.numberFormat(quantity)}
                <Divider type="vertical" />
                出库金额: {common.numberFormat(total)}
            </div>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 1 &&
                    <GoodsReceiptItem
                        isShowPriceCost={action !== 'commodity'}
                        defaultValue={list}
                        productNumber={newValue}
                        action={action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                        chargeType={action === Product.KIND_PART ? ProductGeneralRate.CHARGE_TYPE_REPAIR : ProductGeneralRate.CHARGE_TYPE_SALE}
                        orderType={ProductCategoryRate.ORDER_TYPE_RECEIVE}
                        onOk={productGoodsOnOk} />}
                {modalNumber === 3 &&
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={companyId}
                        isMultiple={false}
                        onOk={(val) => {
                            setEmployee(val)
                            form.setFieldsValue({employeeName: val.name})
                            setVisible(false)
                        }} />}
            </Modal>
        </>
    );
}

export default ReceiveForm