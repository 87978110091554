import React, {useState, useEffect, useRef} from 'react';
import SearchArea from "../../layout/SearchArea";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Input, Pagination, Select, Table, Form, Row, Col, Modal, DatePicker, Divider} from "antd";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";
import {connect} from "react-redux";
import moment from "moment";
import {Link} from "react-router-dom";
import LookupCompany from "../../passport/LookupCompany";
import LookupEmployee from "../../passport/LookupEmployee";
import Search from "../../../utils/search";
import BigNumber from "bignumber.js";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const {Option} = Select;

//订单状态 1:订单待审 11:审核中 12:审核未通过（可以编辑重新提交审核） 2:订单已审 3:订单配车（取消配车） 4:订车出库（取消出库）
// 5:精品出库（取消出库） 6:订单预结（取消预结） 7:订单结算（未收款可取消结算） 8:订单收款（退款） 9:订单完结
const STATUS_UNTRIED = 1;
const STATUS_ADOPT = 2;
const STATUS_EQUIPPED = 3;
const STATUS_KIND_VEHICLE = 4;
const STATUS_KIND_PARTS = 5;
const STATUS_WAIT = 6;
const STATUS_RECEIVABLE = 7;
const STATUS_PAID = 8;
const STATUS_RETREAT = 9;
const STATUS_AUDIT = 11;
const STATUS_AUDIT_REJECT = 12;

const typeName = {
    1: "区域客户订单",
    2: "诚意金订单",
    3: "试驾车订单",
    4: "展车订单",
    5: "调拨订单",
    // 6: "区域/批发订单",
    7: "代交车订单",
    8: "精品订单",
    11: "一般客户订单"
}

const StatusTypeAlias = {
    [STATUS_UNTRIED]: "订单待审",
    [STATUS_ADOPT]: "订单已审",
    [STATUS_EQUIPPED]: "订单配车",
    [STATUS_KIND_VEHICLE]: "订车出库",
    [STATUS_KIND_PARTS]: "精品出库",
    [STATUS_WAIT]: "订单预结",
    [STATUS_RECEIVABLE]: "订单结算",
    [STATUS_PAID]: "订单收款",
    [STATUS_RETREAT]: "已退单",
    [STATUS_AUDIT]: "订单审核中",
    [STATUS_AUDIT_REJECT]: "审核未通过"
}

const STATUS_FLAG_ALL = 0
const STATUS_FLAG_WAITING = 1
const STATUS_FLAG_PAID = 2

const StatusFlagAlias = {
    [STATUS_FLAG_ALL]: [STATUS_WAIT, STATUS_RECEIVABLE, STATUS_PAID],
    [STATUS_FLAG_WAITING]: [STATUS_WAIT],
    [STATUS_FLAG_PAID]: [STATUS_RECEIVABLE, STATUS_PAID],
}

function SalePrepay(props) {
    // 模态框内 使用该组件 一定要传 onOK
    let {
        onOk,
        isMultiple
    } = props
    let buttons = props.buttons || ["settle"]
    let customerId = props.customerId || ''
    let searchRef = useRef(null)
    let initialSearch = {
        vehicleOwner: '',
        vehicleOwnerPhone: '',
        code: "",
        saleName: "",
        saleId: "",
        exampleStatus: buttons.indexOf("checkout") <= -1 ? STATUS_FLAG_WAITING : STATUS_FLAG_ALL,
        statuses: buttons.indexOf("checkout") <= -1 ? StatusFlagAlias[STATUS_FLAG_WAITING] : StatusFlagAlias[STATUS_FLAG_ALL],
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    // let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    // let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [saleId, setSaleId] = useState("") // 当前登陆人的id
    let [dataSource, setDataSource] = useState([])    //维修工单列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [carNum, setCarNum] = useState("0.00")// 合计 车辆总台数

    const columns = [
        {
            title: '订单编号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '业务日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            render: (text, record, index) => {
                if(text === undefined) {
                    return ''
                }

                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '车主名称',
            width: 100,
            dataIndex: 'vehicleOwner',
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                if(text === undefined) {
                    return ''
                }

                return typeName[text]
            }
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== undefined && record.vehicle !== null ? record.vehicle.productName : null
            }
        },
        {
            title: "vin",
            dataIndex: ['vehicle', 'vin'],
            width: 130,
            ellipsis: true,
        },
        {
            title: '订单金额',
            dataIndex: 'actualPayPrice',
            key: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                if(text === undefined) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '预结日期',
            width: 100,
            dataIndex: 'prepayAt',
            key: 'prepayAt',
            ellipsis: true,
            render: (text) => {
                if(text === undefined) {
                    return ''
                }

                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                if(text === undefined) {
                    return ''
                }

                return <div>
                    {StatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '结算日期',
            width: 100,
            dataIndex: 'settlementAt',
            key: 'prepayAt',
            ellipsis: true,
            render: (text) => {
                if(text === undefined) {
                    return ''
                }

                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '操作',
            width: 100,
            fixed: "right",
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                // 小计 不返回
                if(parseFloat(record.id) < 0) {
                    return ''
                }

                return <span className="action-btns">
                    <Link hidden={buttons.indexOf("settle") <= -1} to={`/sale/order/settle/${record.id}`}>结算</Link>
                </span>
            }
        }
    ]
    const columns2 = [
        {
            title: '订单编号',
            width: 100,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '车主名称',
            width: 90,
            dataIndex: 'vehicleOwner',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if(text === undefined) {
                    return ''
                }

                return typeName[text]
            }
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== undefined && record.vehicle !== null ? record.vehicle.productName : null
            }
        },
        {
            title: "vin",
            dataIndex: ['vehicle', 'vin'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '订单金额',
            dataIndex: 'actualPayPrice',
            key: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                if(text === undefined) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 30,
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                if(text === undefined) {
                    return ''
                }

                return <div>
                    {StatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '创建日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            render: (text, record, index) => {
                return text !== undefined && text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        }
    ]

    //页码发生变化就请求数据
    let getPrepays = () => {
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
            isVehicle: buttons.indexOf("checkout") !== -1,
            customerId: customerId
        }
        common.loadingStart()
        common.ajax('get', '/sale/order/vehicleList', params).then(data => {
            if(data.orders.length > 0) {
                let objMy = {
                    id: "-1",
                    actualPayPrice: 0,
                    code: "小计"
                }
                data.orders.forEach((item) => {
                    // 订单金额
                    objMy.actualPayPrice = new BigNumber(item.actualPayPrice).plus(new BigNumber(objMy.actualPayPrice)).toFixed(2)
                })

                data.orders.push(objMy)
            }
            setDataSource(data.orders)
            setTotal(data.pagination.total)
            if(data.summaries != null && data.summaries.carNum !== undefined) {
                setCarNum(data.summaries.carNum)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getPrepays()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //当前登录者的销售顾问
    // useEffect(() => {
    //     common.loadingStart()
    //     common.ajax('get', '/passport/user/findByAuthUser')
    //         .then(res => {
    //             search = {...search, saleId: res.id || "", saleName: res.name}
    //             setSearch(search)
    //             query = {...search, saleId: res.id || "", saleName: res.name}
    //             setQuery(query)
    //             saleId = res.id
    //             setSaleId(saleId)
    //             getPrepays()
    //         })
    //         .finally(common.loadingStop)
    // }, [])


    //表格选择框
    const rowSelection = {
        fixed: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    };

    //确定
    let handleOk = () => {
        if(rows.length === 0) {
            common.toast("请选择客户")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    return (
        <div>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem className={'label-character-3'} label={"订单编号"}>
                                <Input placeholder={"请输入订单编号"} value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车主名称"}>
                                <Input placeholder={"请输入车主名称"} value={search.vehicleOwner} onChange={e => {
                                    setSearch({...search, vehicleOwner: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.minCreatedAt ? moment(search.minCreatedAt) : null,
                                        search.maxCreatedAt ? moment(search.maxCreatedAt) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            minCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            maxCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label="单据状态">
                                <Select
                                    value={search.exampleStatus}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            statuses: StatusFlagAlias[val],
                                            exampleStatus: val
                                        })
                                    }}>
                                    <Option value={STATUS_FLAG_ALL} key={STATUS_FLAG_ALL}>全部</Option>
                                    <Option value={STATUS_FLAG_WAITING} key={STATUS_FLAG_WAITING}>未结算</Option>
                                    <Option value={STATUS_FLAG_PAID} key={STATUS_FLAG_PAID}>已结算</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem className={'label-character-3'} label={"VIN"}>
                                <Input placeholder={"请输入VIN"} value={search.VIN} onChange={e => {
                                    setSearch({...search, VIN: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车主电话"} name='vehicleOwnerPhone'>
                                <Input placeholder={"请输入车主电话"}
                                    value={search.vehicleOwnerPhone}
                                    onChange={e => {
                                        setSearch({...search, vehicleOwnerPhone: e.target.value})
                                    }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            settlementAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售顾问">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    value={search.saleName}
                                    suffix={
                                        search.saleName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择销售顾问')
                                                setVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择销售顾问')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className="search-btns">
                                <Button ref={searchRef} icon={<SearchOutlined />} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, currentPage: 1})
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch({...initialSearch, saleId: saleId})
                                    setQuery({...initialSearch, saleId: saleId})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    if(buttons.indexOf("checkout") > -1) {
                        return {
                            onClick: () => {
                                if(isMultiple) {
                                    let num = -1
                                    rows.forEach((item, number) => {
                                        if(item.id === record.id) {
                                            num = number
                                        }
                                    })
                                    let selectedRows = rows
                                    if(num > -1) {
                                        selectedRows.splice(num, 1)
                                    } else {
                                        selectedRows.push(record)
                                    }
                                    rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                                } else {
                                    rowSelection.onChange([record.id], [record])
                                }
                            }, // 点击行
                        };
                    }
                }}
                rowSelection={buttons.indexOf("checkout") > -1 ? rowSelection : null}
                columns={buttons.indexOf("checkout") <= -1 ? columns : columns2}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={dataSource}
            />

            <PageBottom className={"page-bottom-pagination"}
                children={
                    <div>
                        合计: {new BigNumber(carNum).toNumber()}台
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
            </PageBottom>

            {
                buttons.indexOf("checkout") > -1 &&
                <div className={"modal-footer-btn"}>
                    <Button
                        type={"primary"}
                        onClick={handleOk}>确定
                    </Button>
                </div>
            }

            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setVisible(false)
                }}>
                {modalNumber === 2 &&
                    <LookupCompany isMultiple={false} onOk={(val) => {  //需要多选，则isMultiple=true
                        setVisible(false)
                        setSearch({...search, ownerId: val.id, companyName: val.name})
                    }} />}
                {modalNumber === 1 &&
                    <LookupEmployee
                        onOk={(value) => {
                            setSearch({...search, saleId: value.id, saleName: value.name})
                            setVisible(false)
                        }}
                        isMultiple={false}
                    />}
            </Modal>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SalePrepay)