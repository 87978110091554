import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    CloudUploadOutlined,
    PlusOutlined,
    PrinterOutlined,
    DoubleRightOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    EditOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import BigNumber from "bignumber.js";
import {
    Basic,
    Brand,
    Product,
    ProductCategoryRate,
    Receipt,
    Warehouse
} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import DeliveryForm from "../../part/receipt/component/delivery";
import LookupAll from "../../../../components/passport/LookupAll";
import ReceiptChange from "../receiptChange";
import ExportButton from "../../../../components/export";
import Search from "../../../../utils/search";
import moment from "moment";
import {SupportImages} from "../../../../components/support/config";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [supplier, setSupplier] = useState({});// 供货商
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    let [brand, setBrand] = useState([]);
    let [deliveryButtonDisabled, setDeliveryButtonDisabled] = useState(true);
    let [deliveryData, setDeliveryData] = useState([]);
    let [updateData, setUpdateData] = useState({}) // 入库单修改的数据

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        receiptCode: "",
        number: "",
        productId: "",
        brandIds: brand.map(item => item.id),
        warehouseId: "",
        supplierId: "",
        deliveryNumber: "",
        kind: action,
        ownerId: common.getUser().company.id,
        canRate: Basic.IS_ANSWER_YES,
        orderTypes: [ProductCategoryRate.ORDER_TYPE_REPAIR, ProductCategoryRate.ORDER_TYPE_SALE_WMS],

        // 接口中不存在的字段
        exampleType: "",
        exampleWarehouseList: [],
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [imageUrl, setImageUrl] = useState(null);// logo
    let [subtotal, setSubtotal] = useState({});

    let rowSelection = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list.forEach((item) => {
                        if (item.receiptId === selectedRows[length > 1 ? length - 1 : 0].receiptId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: (record.id === "-1") || (record.id === "-2")
        }),
    }

    const columns = [
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '送货单号',
            width: 150,
            dataIndex: ['receiptDto', 'deliveryNumber'],
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '适用车型',
            width: 100,
            dataIndex: ['productDto', 'applicableModels'],
            ellipsis: true
        },
        {
            title: '仓位',
            width: 100,
            align: 'center',
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            align: 'right',
            dataIndex: 'receiptQuantity',
            ellipsis: true
        },
        {
            title: '单位',
            width: 150,
            align: 'center',
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '入库单价(含税)',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '金额',
            width: 120,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 100,
            align: 'right',
            dataIndex: 'taxPercent',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text
        },
        {
            title: '去税金额',
            width: 120,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 100,
            dataIndex: 'priceTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库类型',
            align: 'center',
            width: 150,
            dataIndex: ['receiptDto', 'type'],
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
        },
        {
            title: '采购人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }
                return text
            }
        },
    ]

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    useEffect(() => {
        if (rows.length > 0 && !(rows[0].receiptDto.type in Receipt.ReceiptReturnTypeAlias)) {
            let arr = rows.filter((item) => item.salableQuantity > 0)
            setDeliveryButtonDisabled(arr.length === 0)

            let res = []
            if (arr.length > 0) {
                arr.forEach((item) => {
                    res.push({
                        ...item,
                        priceCost: item.price,
                        price: null,
                        spec: '',
                        quantity: null,
                    })
                })
            }
            setDeliveryData(res)
        } else {
            setDeliveryData([])
            setDeliveryButtonDisabled(true)
        }
    }, [rows])

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // 更改供货商 ajax
    let supplierChange = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/receiptChange/create", params).then(() => {
            setVisible(false)
            getData()
            setRows([])
            setRowKeys([])
            common.toast("修改供货商成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            if (data.subtotal === null || data.subtotal.price === null) {
                setSubtotal({})
            } else {
                setSubtotal(data.subtotal)
            }

            if (data.receiptItems.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    priceConst: 0,
                    priceTax: 0,
                    priceGo: 0,
                    receiptDate: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    priceConst: data.subtotal.price,
                    priceTax: data.subtotal.priceTax,
                    priceGo: data.subtotal.taxGo,
                    receiptDate: '合计',
                }
                data.receiptItems.forEach((item) => {
                    // 金额
                    item.priceConst = item.total === undefined || item.total === null ? "0.00" : item.total
                    // 去税金额
                    item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
                    // 税金
                    item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax

                    // 小计
                    // 数量
                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toFixed(2)
                    // 金额
                    objMy.priceConst = new BigNumber(item.priceConst).plus(objMy.priceConst).toFixed(2)
                    // 去税金额
                    objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toFixed(2)
                })
                objMy.priceTax = new BigNumber(objMy.priceConst).minus(objMy.priceGo).toFixed(2)

                data.receiptItems.push(objMy)
                data.receiptItems.push(objMy2)
            }
            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 打印全部
    let printAll2 = () => {
        if (total > 300) {
            common.confirm("最多只能打印300条", () => {
                printAll()
            })
        } else {
            printAll()
        }
    }

    let printAll = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/receiptItem/list", {
                ...query,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                limit: 300,
                page: 1,
            }).then(data => {
                resolve(data.receiptItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            printData(printList)
        }).finally(common.loadingStop)
    }

    let printData = (printList) => {
        let total = "0.00";// 总金额
        let quantityTotal = "0.00";
        let min = new Date(printList[0].receiptDate).getTime();
        let minStr = printList[0].receiptDate
        let max = new Date(printList[0].receiptDate).getTime();
        let maxStr = printList[0].receiptDate
        printList.forEach((item) => {
            let time = new Date(item.receiptDate).getTime();
            if (min > time) {
                min = time
                minStr = item.receiptDate
            }

            if (time > max) {
                max = time
                maxStr = item.receiptDate
            }

            // 金额
            item.priceConst = item.total === undefined || item.total === null ? "0.00" : item.total
            // 去税金额
            item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
            // 税金
            item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax

            total = new BigNumber(item.total).plus(total).toFixed(2)
            quantityTotal = new BigNumber(item.receiptQuantity).plus(quantityTotal).toFixed(2)

            item.receiptDate = item.receiptDate.substring(0, 10)
            item.receiptDto.type = item.receiptDto.type in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[item.receiptDto.type] : item.receiptDto.type
        })

        let data = {
            "code": "wms_receipt_all",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": imageUrl || "",// logo
                "title": "入库单",
                "startDate": minStr.substring(0, 10),
                "endDate": maxStr.substring(0, 10),
                "companyName": common.getUser().company.name,
                "upperCase": common.moneyToChinese(total),
                "total": common.numberCut(total, 2),
                "quantityTotal": common.numberCut(quantityTotal, 2),
                "createdAt": printList[0].receiptDate.substring(0, 10),
                "operator": common.getUser().nickname,
                "buyer": printList[0].employeeName,
                "receiptItems": printList
            }
        }

        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).catch(common.loadingStop)
    }

    // 打印
    let print = () => {
        if (rows.length === 0) {
            common.alert("请选择一个入库单");
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/receiptItem/list", {
                ...query,
                receiptId: rows[0].receiptDto.id,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                limit: 1000,
                page: 1,
                orderBy: 'receiptItem.`id` ASC'
            }).then(data => {
                resolve(data.receiptItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = "0.00";// 总金额
            let priceGo = "0.00";// 去税金额
            let priceTax = "0.00";// 税金
            let quantityTotal = "0.00";// 总数量
            printList.forEach((item) => {
                // 去税金额
                item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
                // 税金
                item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax
                item.total = new BigNumber(item.price).multipliedBy(item.receiptQuantity).toFixed(2)

                total = new BigNumber(item.total).plus(total).toFixed(2)
                quantityTotal = new BigNumber(item.receiptQuantity).plus(quantityTotal).toFixed(2)
                priceGo = new BigNumber(item.priceGo).plus(priceGo).toFixed(2)
                priceTax = new BigNumber(item.priceTax).plus(priceTax).toFixed(2)
            })

            let data = {
                "code": "wms_receipt",
                "owner_id": common.getUser().company.id,
                "data": {
                    "taxPercent": printList[0].taxPercent,// 入库税率
                    "receiptType": Receipt.ReceiptTypeAlias[printList[0].receiptDto.type],// 入库类型
                    "companyName": common.getUser().company.name,
                    "code": parseFloat(quantityTotal) < 0 ? "原入库单号: " + printList[0].receiptDto.code : printList[0].receiptDto.code,
                    "total": common.numberCut(total, 2),
                    "priceGo": common.numberCut(priceGo, 2),
                    "priceTax": common.numberCut(priceTax, 2),
                    "quantityTotal": common.numberCut(quantityTotal, 2),
                    "totalUpper": common.moneyToChinese(common.numberCut(total, 2)),
                    "createdAt": printList[0].receiptDate.substring(0, 10),
                    "spec": printList[0].receiptDto.spec,
                    "operator": common.getUser().nickname,
                    "supplierName": printList[0].supplierName,
                    "buyer": printList[0].employeeName,
                    "receiptItems": printList
                }
            }

            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    let getDataForDelivery = () => {
        if (rows.length === 0) {
            common.alert("请选择一个入库单");
            return false
        }

        common.loadingStart();
        common.ajax("get", "/wms/receiptItem/list", {
            ...query,
            receiptId: rows[0].receiptDto.id,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
            limit: 1000,
            page: 1
        }).then(data => {
            data.receiptItems.forEach((item) => {
                item.priceCost = item.price
                item.price = null
                item.spec = ''
                item.quantity = null
            })

            setDeliveryData(data.receiptItems)
            setModalNumber(3)
            setVisible(true)
        }).finally(common.loadingStop)
    }

    // 配件快速出库
    let createDelivery = (params) => {
        setVisible(false)

        common.loadingStart()
        common.ajax("post", params.url, params).then(() => {
            common.toast("配件出库成功")
            setRows([])
            setRowKeys([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品入库" : "配件入库"}>
                <Button type="primary">
                    <Link
                        to={action === Product.KIND_ARTICLE ? '/wms/article/receipt/create' : '/wms/part/receipt/create'}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
                <Button icon={<PrinterOutlined/>} onClick={print}>打印</Button>
                <Button
                    disabled={list.length === 0}
                    icon={<PrinterOutlined/>} onClick={printAll2}>打印全部</Button>
                {(action === Product.KIND_ARTICLE ? common.can('wms.boutique.receipt') : common.can('wms.part.receipt')) &&
                    <Button>
                        <Link to={action === Product.KIND_PART ? '/wms/part/import' : '/wms/article/import'}>
                            <CloudUploadOutlined/>
                            <span> 数据导入</span>
                        </Link>
                    </Button>}
                {(action === Product.KIND_ARTICLE ? common.can('wms.boutique.receipt.pre.import') : common.can('wms.part.receipt.pre.import')) &&
                    <Button>
                        <Link
                            to={action === Product.KIND_PART ? '/wms/part/import/receipt' : '/wms/article/import/receipt'}>
                            <CloudUploadOutlined/>
                            <span> 期初库存导入</span>
                        </Link>
                    </Button>}
                <ExportButton
                    filename={action === Product.KIND_PART ? '配件入库.xlsx' : '精品入库.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        receiptCode: query.receiptCode,
                        number: query.number,
                        productId: query.productId,
                        brandIds: query.brandIds,
                        warehouseId: query.warehouseId,
                        supplierId: query.supplierId,
                        deliveryNumber: query.deliveryNumber,
                        applicableModels: query.applicableModels,
                        receiptSpec: query.receiptSpec,
                        kind: query.kind,
                        ownerId: query.ownerId,
                        type: query.type,
                    })}
                    type={'wms_part_receipt'}
                />
                {action === Product.KIND_PART &&
                    <Button
                        disabled={deliveryButtonDisabled}
                        icon={<DoubleRightOutlined/>}
                        onClick={getDataForDelivery}
                        // onClick={() => {
                        //     setModalNumber(3)
                        //     setVisible(true)
                        // }}
                    >快速出库</Button>}
                {common.can('wms.part.change.supplier') && action === Product.KIND_PART &&
                    <Button
                        icon={<EditOutlined/>}
                        disabled={rows.length === 0}
                        onClick={() => {
                            setUpdateData({
                                receiptId: rows[0].receiptId,
                                ownerId: rows[0].ownerId,
                                supplierName: rows[0].supplierName,
                                oldSupplierId: rows[0].supplierId,
                                kind: action
                            })
                            setModalNumber(5)
                            setVisible(true)
                        }}>更改供货商</Button>}
                {common.can('wms.boutique.change.supplier') && action === Product.KIND_ARTICLE &&
                    <Button
                        icon={<EditOutlined/>}
                        disabled={rows.length === 0}
                        onClick={() => {
                            setUpdateData({
                                receiptId: rows[0].receiptId,
                                ownerId: rows[0].ownerId,
                                supplierName: rows[0].supplierName,
                                oldSupplierId: rows[0].supplierId,
                                kind: action
                            })
                            setModalNumber(5)
                            setVisible(true)
                        }}>更改供货商</Button>}
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}
                                       onPressEnter={() => {
                                           setPagination({...pagination, page: 1})
                                           setQuery(search)
                                           Search.setParams(search)
                                       }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle(action === Product.KIND_ARTICLE ? "选择精品" : "选择配件")
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle(action === Product.KIND_ARTICLE ? "选择精品" : "选择配件")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="入库单号">
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}
                                       onPressEnter={() => {
                                           setPagination({...pagination, page: 1})
                                           setQuery(search)
                                           Search.setParams(search)
                                       }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型" className={'label-character-3'}>
                                <Select value={search.type} onChange={(val) => {
                                    // 入库类型 与 退库类型 合并
                                    setSearch({
                                        ...search,
                                        type: val
                                    })
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                            key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                            key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_BUY}
                                            key={Receipt.RECEIPT_TYPE_RETURN_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_EMPTY} key={Receipt.RECEIPT_TYPE_RETURN_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_ALLOT} key={Receipt.RECEIPT_TYPE_RETURN_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_TRANSFER} key={Receipt.RECEIPT_TYPE_RETURN_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_STOCK} key={Receipt.RECEIPT_TYPE_RETURN_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_STOCK]}</Option>
                                    <Option value={initialSearch.exampleType}
                                            key={initialSearch.exampleType}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="库别">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.exampleWarehouseList}
                                    onChange={(val) => {
                                        setSearch({...search, exampleWarehouseList: val, warehouseId: val.toString()})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="送货单号">
                                <Input value={search.deliveryNumber} onChange={(e) => {
                                    setSearch({...search, deliveryNumber: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds} onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                        })
                                    }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Option key={''} value={''}>全部</Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"适用车型"}>
                                <Input value={search.applicableModels} onChange={(e) => {
                                    setSearch({...search, applicableModels: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"入库备注"}>
                                <Input value={search.receiptSpec} onChange={(e) => {
                                    setSearch({...search, receiptSpec: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    setSupplier({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: () => {
                               if (record.id === "-1" || record.id === "-2") {
                                   return false
                               }

                               if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                                   rowSelection.selectedRowKeys = []
                                   rowSelection.onChange([], [], 1)
                               } else {
                                   let arr = []
                                   list.forEach((item) => {
                                       if (item.receiptId === record.receiptId) {
                                           arr.push(item)
                                       }
                                   })
                                   rowSelection.selectedRowKeys = arr.map((item) => item.id)
                                   rowSelection.onChange(arr.map((item) => item.id), arr, 1)
                               }
                           }, // 点击行
                       };
                   }}
                   scroll={{x: '100%', y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {subtotal.quantity}
                        <Divider type="vertical"/>
                        入库金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        isHaltProduction={'all'}
                        action={'all'}
                        defaultValue={action}
                        isMultiple={false}
                        onOk={(val) => {
                            setProduct(val)
                            setSearch({...search, productId: val.id})
                            setVisible2(false)
                        }}/>}
                {modalNumber === 2 &&
                    <LookupAll
                        isCompany={true}
                        isPartner={true}
                        isMultiple={false}
                        onOk={(value) => {
                            setSupplier({id: value.id, name: value.name})
                            setSearch({...search, supplierId: value.id})
                            setVisible2(false)
                        }}
                    />}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                    <DeliveryForm action={action} defaultValue={deliveryData} onOk={createDelivery}/>}
                {modalNumber === 5 &&
                    <ReceiptChange defaultValue={updateData} onOk={supplierChange}/>}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)