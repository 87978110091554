import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined, PrinterOutlined, CloseOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, Row, Modal, Form, Select, DatePicker, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import PageTop from "../../../../components/layout/PageTop"
import DeliveryForm from "./component/delivery"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import {Basic, Delivery, Product, RepairOrder} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import RepairHistory from "../../../../components/support/RepairHistory";
import RepairDetail from "../../../../components/support/RepairDetail";
import Search from "../../../../utils/search";
import {SupportImages} from "../../../../components/support/config";

//普通工单
//  3.维修中（配件出库，取消出库）
const REPAIR_STATUS_SUPPORTING = 3;

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料
//                  4.礼券 5.委外维修项目 6.委外维修材料
//                  7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PRODUCT = '3';

const {RangePicker} = DatePicker;

function Order() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [imageUrl, setImageUrl] = useState(null);
    let initialSearch = {
        name: '',
        plate: '',
        phone: '',
        code: '',
        selectStatus: 1, //在厂车辆
        statuses: [
            RepairOrder.STATUS_CREATING,
            RepairOrder.STATUS_WAITING,
            RepairOrder.STATUS_SUPPORTING,
            RepairOrder.STATUS_SUPPORTED,
            RepairOrder.STATUS_PREPAID,
            RepairOrder.STATUS_SETTLED],
        employeeId: "",
        employeeName: "",
        makeAtStart: "",
        makeAtEnd: "",
    }

    let initRepairStatistics = {
        inFactoryVehicleNum: 0,
        waitingVehicleNum: 0,
        supportingVehicleNum: 0,
        supportedVehicleNum: 0,
    }
    let [tableList, setTableList] = useState([]) // 列表数据
    let [order, setOrder] = useState({})
    let [visible, setVisible] = useState(false)// 模态框可见性
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值
    let [number, setNumber] = useState(0)   //选择具体哪个模态框
    let [list2, setList2] = useState([])   // 订单详情
    let [rowKeys, setRowKeys] = useState([])
    let [repairStatistics, setRepairStatistics] = useState(initRepairStatistics) //车辆统计数据

    let [rows, setRows] = useState([]) // 打印时 选择的数据
    let [selectOrder, setSelectOrder] = useState({}) // 打印时 选择的订单
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)    // 选择员工弹框
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false) //选择维修记录
    let [findRepairVisible, setFindRepairVisible] = useState(false)    // 查看工单详情弹框
    let [vin, setVin] = useState("") //已选择的车架号
    let [plate, setPlate] = useState("") //已选择的车牌号
    let [code, setCode] = useState("") //已选择的工单编号
    let [canDeliveryAtDispatch, setCanDeliveryAtDispatch] = useState(false) //不派工允许出料
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let companyId = common.getUser().company.id

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }
    // 获取列表数据
    let getData = () => {
        let params = {
            ...pagination,
            ...query,
            ownerId: companyId,
            statuses: [],
        }
        if (query.selectStatus === 0) {
            params.statuses = [
                RepairOrder.STATUS_CREATING,
                RepairOrder.STATUS_WAITING,
                RepairOrder.STATUS_SUPPORTING,
                RepairOrder.STATUS_SUPPORTED,
                RepairOrder.STATUS_PREPAID,
                RepairOrder.STATUS_SETTLED,
                RepairOrder.STATUS_RECEIVED]
        } else if (query.selectStatus === 1) { //在厂车辆
            params.statuses = [
                RepairOrder.STATUS_CREATING,
                RepairOrder.STATUS_WAITING,
                RepairOrder.STATUS_SUPPORTING,
                RepairOrder.STATUS_SUPPORTED,
                RepairOrder.STATUS_PREPAID]
        } else if (query.selectStatus === 2) { //已结算、已收款
            params.statuses = [
                RepairOrder.STATUS_SETTLED,
                RepairOrder.STATUS_RECEIVED,
            ]
        }
        common.loadingStart();
        common.ajax("get", "/support/repair/list", params).then((data) => {
            setTableList(data.repairs)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    //不派工允许出料
    let findCanDeliveryAtDispatch = () => {
        common.loadingStart()
        common.ajax('get', '/support/setting/canDeliveryAtDispatch')
            .then(res => {
                setCanDeliveryAtDispatch(res)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findCanDeliveryAtDispatch();
    }, [])

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    //获取车辆维修统计
    // let getRepairStatistics = () => {
    //     common.ajax('get', '/support/repair/statistics', {
    //         ...pagination,
    //         ...query,
    //         statuses: [],
    //         ownerId: companyId,
    //     })
    //         .then(res => {
    //             setRepairStatistics(res)
    //         })
    // }
    // useEffect(getRepairStatistics, [])

    const columns = [
        {
            title: '报修日期',
            dataIndex: 'makeAt',
            width: 100,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        setPlate(text)
                        setRepairHistoryVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '',
            width: 30,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        setVin(text)
                        setRepairHistoryVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '工单类型',
            dataIndex: 'repairCategoryName',
            width: 80,
            ellipsis: true,
        },
        {
            title: '出库总额',
            width: 80,
            ellipsis: true,
            align: "right",
            render: ((text, record) => {
                let deliveryTotal = "0.00"
                let repairItems = record.repairItems || []
                repairItems.forEach(item => {
                    //维修明细不等于预估材料
                    if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                        deliveryTotal = common.numberHalfUp(new BigNumber(deliveryTotal).plus(new BigNumber(item.deliveryPrice).multipliedBy(item.quantity)).toString(), 2)
                    }
                })
                return <div>
                    {common.numberFormat(deliveryTotal)}
                </div>
            })
        },
        {
            title: '',
            width: 20,
        },
        {
            title: '服务顾问',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in RepairOrder.StatusAlias ? RepairOrder.StatusAlias[text] : text
            )
        },
        {
            title: '工单编号',
            dataIndex: 'code',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        setCode(text)
                        setFindRepairVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '发动机号',
            dataIndex: 'engineNum',
            width: 100,
            ellipsis: true
        },
        {
            title: '送修人',
            dataIndex: 'name',
            width: 120,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true
        },
        {
            title: '电话',
            dataIndex: 'phone',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        },
        {
            title: '操作',
            width: 100,
            ellipsis: true,
            fixed: 'right',
            align: 'center',
            render: (text, record) =>
                <div className={"action-btns"}>
                    <Button
                        disabled={!(canDeliveryAtDispatch ? record.status <= REPAIR_STATUS_SUPPORTING : record.status === REPAIR_STATUS_SUPPORTING)}
                        type={"link"}
                        onClick={() => {
                            setOrder(record)
                            setNumber(1)
                            setVisible(true)
                        }}>出库</Button>
                    <Button type={"link"} onClick={() => {
                        setSelectOrder(record)
                        getDeliveryData(record.id)
                    }}>查看</Button>
                </div>
        }
    ]
    const columns2 = [
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text === Delivery.DELIVERY_ITEM_STATUS_SHIPPED ? "已发放" : text
            )
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库单明细备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: ['deliveryDto', 'spec'],
            width: 200,
            ellipsis: true
        },
        {
            title: '领料人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true
        }
    ]

    // 获取订单出库信息
    let getDeliveryData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ownerId: companyId,
            orderId: params,
            kind: Product.KIND_PART,
            status: Delivery.DELIVERY_ITEM_STATUS_SHIPPED,
            isReceipt: Basic.IS_ANSWER_YES,
            ignoreAuthority: Basic.IS_ANSWER_YES,
            limit: 500
        }).then((data) => {
            setList2(data.deliveryItems)
            setNumber(2)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 打印数据的选择
    let rowSelection = {
        selectedRowKeys: rowKeys,
        // columnTitle: ' ',
        hideSelectAll: true,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list2.forEach((item) => {
                        if (item.deliveryId === selectedRows[length > 1 ? length - 1 : 0].deliveryId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        }
    }

    // 打印
    let print = () => {
        if (rows.length === 0) {
            common.toast("请选择一个出库单");
            return false
        }
        // 出库数量*出库价
        let total = '0.00';
        // 入库单价*出库数量
        let receiptTotal = '0.00';
        rows.forEach((item) => {
            // 出库金额
            item.total = common.numberCut(new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2), 2)
            // 成本金额
            item.receiptTotal = common.numberCut(new BigNumber(item.quantity).multipliedBy(item.receiptItemDto.price).toFixed(2), 2)
            // 出库总价
            total = new BigNumber(item.total).plus(total).toFixed(2)
            // 入库总价
            receiptTotal = new BigNumber(item.receiptTotal).plus(receiptTotal).toFixed(2)
        })

        // 组装数据
        let data = {
            "code": "wms_delivery_position",
            "owner_id": companyId,
            "data": {
                "logo": imageUrl || null,
                "title": "维修出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": selectOrder.customerName,
                    "code": selectOrder.code,
                    "plate": selectOrder.plate
                },
                "code": rows[0].deliveryDto.code,
                "total": common.numberCut(total, 2),
                "receiptTotal": common.numberCut(receiptTotal, 2),
                "createdAt": rows[0].deliveryDate.substring(0, 10),
                "spec": rows[0].deliveryDto.spec,
                "provider": rows[0].employeeName,
                "receiver": rows[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": rows
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <PageTop title={"维修工单查询"} />
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="送修人员">
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="工单状态">
                                <Select value={search.selectStatus} onChange={(val) => {
                                    setSearch({...search, selectStatus: val})
                                }}>
                                    {/*在厂车辆 ： 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）
                                    4.已竣工（取消竣工） 5.订单预结（取消预结） 6.订单结算（未收款可取消结算）*/}
                                    <Select.Option
                                        value={1} //1表示在厂车辆
                                        key={1}>在厂车辆</Select.Option>
                                    <Select.Option  //已结算包括 已结算、已收款
                                        value={2} //已结算
                                        key={2}>已结算</Select.Option>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号码">
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        Search.setParams(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="工单编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        Search.setParams(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        Search.setParams(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"报修日期"}>
                                <RangePicker
                                    value={[search.makeAtStart === "" ? null : moment(search.makeAtStart),
                                    search.makeAtEnd === "" ? null : moment(search.makeAtEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                makeAtStart: "",
                                                makeAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                makeAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                makeAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"服务顾问"}>
                                <Input
                                    value={search.employeeName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择员工名称'}
                                    suffix={
                                        search.employeeName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, employeeId: "", employeeName: ""})
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={tableList}
                scroll={{x: '100%'}} />
            <PageBottom
                /*children={
                    <div>
                        在厂车辆：{repairStatistics.inFactoryVehicleNum || 0}
                        <Divider type="vertical"/>
                        未派工：{repairStatistics.waitingVehicleNum || 0}
                        <Divider type="vertical"/>
                        维修中：{repairStatistics.supportingVehicleNum || 0}
                        <Divider type="vertical"/>
                        竣工未结：{repairStatistics.supportedVehicleNum || 0}
                    </div>
                }*/
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange} />
                } />
            <Modal
                maskClosable={false}
                visible={visible}
                width={1200}
                onCancel={() => {
                    setOrder({})
                    setSelectOrder({})
                    setVisible(false)
                }}
                footer={null}
                destroyOnClose={true}
            >
                {number === 2 &&
                    <>
                        <br />
                        <PageTop title={"出库详情"}>
                            <Button icon={<PrinterOutlined />} onClick={print} disabled={rows.length === 0}>打印</Button>
                        </PageTop>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="工单编号">
                                    <Input
                                        defaultValue={selectOrder.code}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="客户名称">
                                    <Input
                                        defaultValue={selectOrder.customerName}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="车牌号">
                                    <Input
                                        defaultValue={selectOrder.plate}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="业务部门">
                                    <Input
                                        defaultValue={selectOrder.departmentName === null ? '暂无部门' : selectOrder.departmentName}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="业务顾问">
                                    <Input
                                        defaultValue={selectOrder.employeeName}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form
                            name={'validate_other2'}
                            className={"ant-advanced-inline-form"}>
                            <Table
                                columns={columns2}
                                rowKey="id"
                                dataSource={list2}
                                pagination={false}
                                rowSelection={rowSelection}
                                onRow={record => {
                                    return {
                                        onClick: () => {
                                            if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                                                rowSelection.selectedRowKeys = []
                                                rowSelection.onChange([], [], 1)
                                            } else {
                                                let arr = []
                                                list2.forEach((item) => {
                                                    if (item.deliveryId === record.deliveryId) {
                                                        arr.push(item)
                                                    }
                                                })
                                                rowSelection.selectedRowKeys = arr.map(item => item.id)
                                                rowSelection.onChange(arr.map(item => item.id), arr, 1)
                                            }
                                        }, // 点击行
                                    };
                                }}
                                scroll={{x: '100%', y: '300px'}} />
                        </Form>
                    </>}
                {number === 1 &&
                    <DeliveryForm
                        defaultValue={order}
                    />}
            </Modal>

            {/*服务顾问*/}
            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={companyId}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>

                {/*车辆维修记录*/}
                <div>
                    <Modal
                        maskClosable={false}
                        title="车辆维修记录"
                        visible={repairHistoryVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setRepairHistoryVisible(false)
                            getData()
                        }}>
                        <RepairHistory vin={vin} plate={plate} />
                    </Modal>
                </div>

                {/*维修工单*/}
                <Modal title={"维修工单详情"}
                    maskClosable={false}
                    visible={findRepairVisible}
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setFindRepairVisible(false)
                    }}>
                    <RepairDetail code={code} />
                </Modal>

            </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)