import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Input,
    Row,
    Form,
} from "antd";
import common from "../../../utils/common"
import {
    SaveOutlined,
} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import moment from "moment";

function OrderDelivery(props) {
    let {onOk, values} = props

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk(values)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({createdAt: moment(res).format("YYYY-MM-DD HH:mm")})
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()
        if (values && values.id) {
            form.setFieldsValue(values)
        }
    }, [])

    return (
        <>
            <br />
            <PageTop title={"新增维修提醒"}>
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                name={'form-data-children'}
                onFinish={onFinish}
                initialValues={{
                    title: "",
                    creatorName: common.getUser().nickname,
                    createdAt: "",
                }}
                form={form}>
                <Form.Item name={'id'} hidden>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'title'}
                            label="提醒内容"
                            rules={[{required: true, message: '提醒内容不允许为空'}]}
                            className={'label-character-4'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={'creatorName'}
                            className={'label-character-4'}
                            label="创建人">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'createdAt'}
                            label="记录时间">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {/*<Modal*/}
            {/*    maskClosable={false}*/}
            {/*    visible={visible}*/}
            {/*    title={modalTitle}*/}
            {/*    width={1000}*/}
            {/*    onCancel={() => {*/}
            {/*        setVisible(false)*/}
            {/*    }}*/}
            {/*    destroyOnClose={true}*/}
            {/*    footer={null}*/}
            {/*>*/}
            {/*    {modalNumber === 3 &&*/}
            {/*        <RepairDetail code={defaultValue.code}/>}*/}
            {/*    {modalNumber === 1 &&*/}
            {/*        <GoodsReceiptItem*/}
            {/*            defaultValue={list}*/}
            {/*            productNumber={newValue}*/}
            {/*            action={Product.KIND_PART}*/}
            {/*            chargeType={ProductGeneralRate.CHARGE_TYPE_SALE}*/}
            {/*            orderType={ProductCategoryRate.ORDER_TYPE_REPAIR}*/}
            {/*            onOk={productGoodsOnOk}/>}*/}
            {/*    {modalNumber === 2 &&*/}
            {/*        <LookupEmployee*/}
            {/*            canChangeCompany={true}*/}
            {/*            companyId={common.getUser().company.id}*/}
            {/*            isMultiple={false}*/}
            {/*            onOk={(val) => {*/}
            {/*                setEmployee(val)*/}
            {/*                if (val.groupId !== "") {*/}
            {/*                    let number = 0*/}
            {/*                    groups.forEach((item) => {*/}
            {/*                        if (item.id === val.groupId) {*/}
            {/*                            number++*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                    if (number !== 0) {*/}
            {/*                        form.setFieldsValue({*/}
            {/*                            groupId: val.groupId,*/}
            {/*                            employeeName: val.name*/}
            {/*                        })*/}
            {/*                    } else {*/}
            {/*                        form.setFieldsValue({*/}
            {/*                            employeeName: val.name*/}
            {/*                        })*/}
            {/*                    }*/}
            {/*                } else {*/}
            {/*                    form.setFieldsValue({*/}
            {/*                        employeeName: val.name*/}
            {/*                    })*/}
            {/*                }*/}
            {/*                setVisible(false)*/}
            {/*            }}/>}*/}
            {/*</Modal>*/}
        </>
    );
}

export default OrderDelivery