import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    RollbackOutlined,
    SearchOutlined,
    SelectOutlined,
    SwapOutlined,
    EditOutlined
} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table, Tooltip} from "antd";
import {Term} from '../../../components/finance/config';

import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import "./index.less"
import moment from "moment";
import BigNumber from "bignumber.js";
import Quick from "./components/quick";
import LookupAll from "../../../components/passport/LookupAll";
import CreateTransfer from "./transfer/create"
import TransferList from "./transfer/list";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";

const BALANCE_TYPE_SALE = 1
const BALANCE_TYPE_SUPPORT = 2
const BALANCE_TYPE_VIP = 3

const BALANCE_TYPE_INFO = {
    [BALANCE_TYPE_SALE]: "销售预收",
    [BALANCE_TYPE_SUPPORT]: "售后预收",
    [BALANCE_TYPE_VIP]: "会员储值"
}

const ADVANCE_INCOME__INFO = {
    [Term.FLAG_ADVANCE_INCOME_SIMPLE]: '一般预收',
    [Term.FLAG_ADVANCE_INCOME_VIP]: '会员储值',
    [Term.FLAG_ADVANCE_INCOME_PAY]: '代收代支',
    [Term.FLAG_ADVANCE_INCOME_COUPON]: '礼券预收',
    [Term.FLAG_ADVANCE_INCOME_ACCESSORY]: '加装预收'
}

const SOURCE_COLLECT = 1
const SOURCE_BRANCH = 2
const SOURCE_RETREAT = 3

const SOURCE_INFO = {
    [SOURCE_COLLECT]: '收',
    [SOURCE_BRANCH]: '支',
    [SOURCE_RETREAT]: '退'
}

function Balance(props) {
    let [financeCategoryFlagList, setFinanceCategoryFlagList] = useState([])

    const saleColumns = [
        {
            title: '客户编号',
            dataIndex: "customerCode",
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <span>{text}</span>
                        <Tooltip> <QuestionCircleOutlined onClick={() => {
                            setCustomerId(record.customerId)
                            setLookupCustomerRecordVisible(true)
                        }}/> </Tooltip>
                    </div>
                )
            }
        },
        {
            title: '联系电话',
            width: 120,
            ellipsis: true,
            dataIndex: "customerMobile",
            render: (text) => {
                return <span className="ant-form-text">
                    {common.can('important.mobile') ? text : text.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}
                </span>
            }
        },
        {
            title: '一般预收',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_SIMPLE) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_SIMPLE).total) : '0.00'}</span>
            }
        },
        {
            title: '代收代支',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_PAY) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_PAY).total) : '0.00'}</span>
            }
        },
        {
            title: '礼券预收',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_COUPON) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_COUPON).total) : '0.00'}</span>
            }
        },
        {
            title: '合计',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                let total = new BigNumber('0')
                record.balances.map(item => total = total.plus(new BigNumber(item.total)))
                return <span>{common.numberFormat(total.toString())}</span>
            }
        },
        {
            title: '锁定',
            width: 120,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                let total = new BigNumber('0')
                record.balances.map(item => total = total.plus(new BigNumber(item.total)))
                let availableTotal = new BigNumber('0')
                record.balances.map(item => availableTotal = availableTotal.plus(new BigNumber(item.available)))
                let diffTotal = total.minus(availableTotal)
                return <span>{diffTotal.toString()}</span>
            }
        },
        {
            title: '可用余额',
            width: 120,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                let availableTotal = new BigNumber('0')
                record.balances.map(item => availableTotal = availableTotal.plus(new BigNumber(item.available)))
                return <span>{common.numberFormat(availableTotal.toString())}</span>
            }
        },
        {
            title: '最后一次收款时间',
            dataIndex: 'lastTimeAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            }
        }
    ];

    const InfoColumns = [
        {
            title: '收支单号',
            dataIndex: "receiptCode",
            width: 100,
            ellipsis: true,
            align: "center",
            render: (text, record, index) => {
                if (record.isEmpty) {
                    return ''
                }
                // return text ? text : '-'
                return (
                    text ? <a href='/' target='_blank' onClick={(e) => {
                        e.preventDefault()
                        props.history.push(`/finance/receipt/view/${record.receiptId}`)
                    }}>{text}</a> : '-'
                )
            }
        },
        {
            title: "转账单号",
            dataIndex: "transferCode",
            width: 100,
            ellipsis: true,
            align: "center",
            render: (text, record, index) => {
                if (record.isEmpty) {
                    return ''
                }
                return (
                    <div className={'action-btns'}>
                        <Button disabled={!record.transferId} type={'link'} onClick={() => {
                            setTransferId(record.transferId)
                            setTransferListVisible(true)
                        }}>{text ? text : '-'}</Button>
                    </div>

                )
            }
        },
        {
            title: '发生时间',
            dataIndex: "createdAt",
            width: 100,
            ellipsis: true,
            align: "center",
            render: (text, record, index) => {
                if (record.isEmpty) {
                    return ''
                }
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm');
            }
        },
        {
            title: '预收类型',
            dataIndex: "type",
            width: 100,
            ellipsis: true,
            align: "center",
            render: (text, record, index) => {
                if (record.isEmpty) {
                    return ''
                }
                return text in BALANCE_TYPE_INFO ? BALANCE_TYPE_INFO[text] : text
            }
        },
        {
            title: '收支类别',
            dataIndex: "source",
            width: 100,
            ellipsis: true,
            align: "center",
            render: (text, record, index) => {
                if (record.isEmpty) {
                    return ''
                }
                return text in SOURCE_INFO ? SOURCE_INFO[text] + ADVANCE_INCOME__INFO[record.financeCategoryFlag] : text
            }
        },
        {
            title: '金额',
            dataIndex: "money",
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmpty) {
                    let money = '0'
                    infoData.forEach(item => {
                        money = new BigNumber(money).plus(new BigNumber(item.money)).toString()
                    })
                    return common.numberFormat(money)
                }
                return (
                    <div>
                        {
                            record.source == SOURCE_BRANCH &&
                            record.financeCategoryFlag == Term.FLAG_ADVANCE_INCOME_COUPON &&
                            common.can('finance.advance.consume.change') ?
                                <Tooltip title="警告：请核对无误后点击处理" color={"red"} key={"red"}>
                                <span icon="" style={{cursor: "pointer"}} onClick={() => {
                                    let money = new BigNumber(record.money).abs().toString();
                                    let message = "点击确认后，该账户明细金额将变为0.00，账户余额将增加" + common.numberFormat(money);
                                    common.confirm(message, () => {
                                        changeConsume(record)
                                    })
                                }}>
                                    {record.money}<EditOutlined/>
                                </span>
                                </Tooltip> : record.money
                        }

                    </div>
                )
            },
        },
        {
            title: '经办人',
            dataIndex: "creatorName",
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmpty) {
                    return ''
                }
                return text
            },
        },
        {
            title: '备注',
            dataIndex: "spec",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmpty) {
                    return ''
                }
                return text
            },
        },
        {
            title: '转账备注',
            dataIndex: 'transferRemarks',
            align: 'center',
            width: 100,
            ellipsis: true,
        }
    ]
    const [form] = Form.useForm();

    //选择客户
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [lookupVisible, setLookupVisible] = useState(false)
    let [quickType, setQuickType] = useState(1)
    let [quick, setQuick] = useState(false)

    let [createTransferVisible, setCreateTransferVisible] = useState(false)
    let [transferListVisible, setTransferListVisible] = useState(false)
    let [transferId, setTransferId] = useState(false)

    let initialSearch = {
        type: Term.defaultTypeOfAdvanceBalanceSupport,
        customer: {id: '', name: ''},
        customerPhone: '',
        plate: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    let [data, setData] = useState([])     // 数据列表
    let [infoData, setInfoData] = useState([])
    let [total, setTotal] = useState(0)    //总记录条数
    let [info, setInfo] = useState(false)

    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框
    let [customerId, setCustomerId] = useState('')

    let [rowKeys, setRowKeys] = useState([])

    let initSummaries = {
        total: "0.00",
        advance_income_simple: "0.00",
        advance_income_pay: "0.00",
        advance_income_coupon: "0.00",
    }
    let [summaries, setSummaries] = useState({})

    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    // 获取预收收款默认类别
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            taxonomy: Term.defaultTypeOfAdvanceBalance
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params)
            .then(res => {
                if (res !== null) {
                    setSearch({
                        ...search,
                        type: parseInt(res.flag)
                    })
                    setQuery({
                        ...query,
                        type: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }, [])

    let getFinanceCategory = () => {
        common.loadingStart()
        common.ajax('get', '/finance/term/list', {
            taxonomy: Term.TAXONOMY_FINANCE_CATEGORY,// 预收款财务类别
            flag: 'advance_income_',// 模糊搜索
            status: Term.STATUS_YES,// 启用
            page: 1,
            limit: 100,
        }).then(res => {
            if (res.terms && res.terms.length > 0) {
                setFinanceCategoryFlagList(res.terms.map(item => item.flag))
            }
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getFinanceCategory()
    }, [])

    // 获取客户的预收款管理列表
    let findCustomerBalances = () => {
        let params = {
            type: query.type,
            customerId: query.customer.id,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
            customerPhone: query.customerPhone,
            plate: query.plate
        }
        common.loadingStart()
        common.ajax("get", "/finance/balance/customerList", params).then((data) => {
            data.customers = data.customers.filter(item => item.balances.length == item.balances.filter(bItem => bItem.financeCategoryFlag).length)
            data.customers.forEach(customer => {
                let map = new Map();
                customer.balances.forEach(balance => {
                    map.set(balance.financeCategoryFlag, balance);
                })
                customer.data = map
            })
            setData(data.customers || [])
            setTotal(data.pagination.total)
            setSummaries(data.summaries || initSummaries)
            setRowKeys([])

            // 不显示账户明细
            setInfoData([])
            setInfo(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        findCustomerBalances()
    }, [pagination, query, quick])

    // 更改流水
    let changeConsume = (record) => {
        let params = {
            ownerId: common.getUser().company.id,
            consumeId: record.id
        }
        common.loadingStart()
        common.ajax("post", "/finance/balance/operateByConsume", params).then(data => {
            common.alert("更改成功");
            record.money = "0.00";
            findCustomerBalances();
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        // 清空选中的数据
        setRowKeys([])
        setInfoData([])
        setInfo(false)

        setPagination({currentPage, pageSize})
    }

    //提交 收|退预收款
    // let onQuick = (params) => {
    // setLookupVisible(false)
    // setQuick(!quick)
    // }

    const rowSelection = {
        selectedRowKeys: rowKeys, //选中的列
        type: 'radio',  //单选
        onChange: (selectedRowKeys, selectedRows) => {
            // setSelectedVehicles(selectedRows)
            setInfo(true)
            setInfoData(selectedRows[0].consumes)
            setRowKeys(selectedRowKeys)
        }
    };

    const extraColumn = [{
        title: '加装预收',
        dataIndex: 'accessoryAdvance',
        width: 120,
        ellipsis: true,
        align: 'right',
        render: (text, record) => {
            return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_ACCESSORY) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_ACCESSORY).total) : '0.00'}</span>;
        }
    }];

    const supportColumnsStart = [
        {
            title: '客户编号',
            dataIndex: "customerCode",
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <span>{text}</span>
                        <Tooltip> <QuestionCircleOutlined onClick={() => {
                            setCustomerId(record.customerId)
                            setLookupCustomerRecordVisible(true)
                        }}/> </Tooltip>
                    </div>
                )
            }
        },
        {
            title: '联系电话',
            width: 120,
            ellipsis: true,
            dataIndex: "customerMobile",
            render: (text) => {
                return <span className="ant-form-text">
                    {common.can('important.mobile') ? text : text.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}
                </span>
            }
        },
        {
            title: '一般预收',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_SIMPLE) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_SIMPLE).total) : '0.00'}</span>
            }
        },
        {
            title: '代收代支',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_PAY) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_PAY).total) : '0.00'}</span>
            }
        },
        {
            title: '礼券预收',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_COUPON) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_COUPON).total) : '0.00'}</span>
            }
        }
    ];
    const supportColumnsEnd = [
        {
            title: '会员储值',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <span>{record.data.has(Term.FLAG_ADVANCE_INCOME_VIP) ? common.numberFormat(record.data.get(Term.FLAG_ADVANCE_INCOME_VIP).total) : '0.00'}</span>
            }
        },
        {
            title: '合计',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                let total = new BigNumber('0')
                record.balances.map(item => total = total.plus(new BigNumber(item.total)))
                return <span>{common.numberFormat(total.toString())}</span>
            }
        },
        {
            title: '锁定',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                let total = new BigNumber('0')
                record.balances.map(item => total = total.plus(new BigNumber(item.total)))
                let availableTotal = new BigNumber('0')
                record.balances.map(item => availableTotal = availableTotal.plus(new BigNumber(item.available)))
                let diffTotal = total.minus(availableTotal)
                return <span>{diffTotal.toString()}</span>
            }
        },
        {
            title: '可用余额',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                let availableTotal = new BigNumber('0')
                record.balances.map(item => availableTotal = availableTotal.plus(new BigNumber(item.available)))
                return <span>{common.numberFormat(availableTotal.toString())}</span>
            }
        },
        {
            title: '最后一次收款时间',
            dataIndex: 'lastTimeAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            }
        }
    ];

    return (
        <React.Fragment>

            <PageTop title="预收款管理">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setQuickType(1)
                    setLookupVisible(true)
                }}>收预收款</Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    setQuickType(0)
                    setLookupVisible(true)
                }}>退预收款</Button>
                <Button icon={<SwapOutlined/>} onClick={() => {
                    setCreateTransferVisible(true)
                }}>转账</Button>
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    type="text"
                                    placeholder={'客户名称'}
                                    suffix={
                                        search.customer.id === '' ? <span></span> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customer: initialSearch.customer})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                    value={search.customer.name}
                                />

                                <Modal title={'选择客户'}
                                       maskClosable={false}
                                       visible={lookupCustomerVisible}
                                       width={1000}
                                       footer={null}
                                       onCancel={() => {
                                           setLookupCustomerVisible(false)
                                       }}>
                                    <LookupAll isMultiple={false} isVehicle={true} isCustomer={true} isPartner={true}
                                               isCompany={true}
                                               onOk={val => {
                                                   setSearch({...search, customer: val})
                                                   setLookupCustomerVisible(false)
                                               }}/>
                                </Modal>

                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.customerPhone} onChange={e => {
                                    setSearch({...search, customerPhone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌">
                                <Input value={search.plate} onChange={e => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="预收类型">
                                <Select value={search.type} onChange={e => {
                                    setSearch({
                                        ...search,
                                        type: e
                                    })
                                }}>
                                    <Select.Option value={Term.defaultTypeOfAdvanceBalanceSale}
                                                   key={Term.defaultTypeOfAdvanceBalanceSale}>销售预收</Select.Option>
                                    <Select.Option value={Term.defaultTypeOfAdvanceBalanceSupport}
                                                   key={Term.defaultTypeOfAdvanceBalanceSupport}>售后预收</Select.Option>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="search-btns">
                                <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setInfo(false)
                                    setInfoData([])
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setInfo(false)
                                    setInfoData([])
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table rowKey={record => record.customerId}
                   scroll={{x: true}}  // 指定x方向滚动
                   dataSource={data}
                   columns={query.type == 1 ? saleColumns : (financeCategoryFlagList.includes(Term.FLAG_ADVANCE_INCOME_ACCESSORY) ? [...supportColumnsStart, ...extraColumn, ...supportColumnsEnd] : [...supportColumnsStart, ...supportColumnsEnd])}
                   pagination={false}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: event => {
                               setInfo(true)
                               setInfoData(record.consumes)
                               rowSelection.onChange([record.customerId], [record])
                           }
                       }
                   }}
                   rowClassName={record => record.SelectType ? "Select" : ""}
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.currentPage}
                    current={pagination.currentPage}
                    showSizeChanger
                    defaultPageSize={pagination.pageSize}
                />
            }>
                {
                    query.type == BALANCE_TYPE_SALE ?
                        <span>
                            总预收: {summaries.total ? common.numberFormat(summaries.total) : '0.00'}&nbsp;&nbsp;
                            一般预收: {summaries.advance_income_simple ? common.numberFormat(summaries.advance_income_simple) : '0.00'}&nbsp;&nbsp;
                            代收代支: {summaries.advance_income_pay ? common.numberFormat(summaries.advance_income_pay) : '0.00'}&nbsp;&nbsp;
                            礼券预收: {summaries.advance_income_coupon ? common.numberFormat(summaries.advance_income_coupon) : '0.00'}&nbsp;&nbsp;
                        </span>
                        : <span>
                            总预收: {summaries.total ? common.numberFormat(summaries.total) : '0.00'}&nbsp;&nbsp;
                            一般预收: {summaries.advance_income_simple ? common.numberFormat(summaries.advance_income_simple) : '0.00'}&nbsp;&nbsp;
                            代收代支: {summaries.advance_income_pay ? common.numberFormat(summaries.advance_income_pay) : '0.00'}&nbsp;&nbsp;
                            礼券预收: {summaries.advance_income_coupon ? common.numberFormat(summaries.advance_income_coupon) : '0.00'}&nbsp;&nbsp;
                            会员储值: {summaries.advance_income_vip ? common.numberFormat(summaries.advance_income_vip) : '0.00'}&nbsp;&nbsp;
                        </span>
                }
            </PageBottom>

            {
                info > 0 ?
                    <div>
                        <PageTop title={'账户明细'}/>
                        <Table rowKey={record => record.id}
                               scroll={{x: true}}  // 指定x方向滚动
                               dataSource={[...infoData, {
                                   isEmpty: true,
                                   id: '',
                               }]}
                               columns={InfoColumns}
                               pagination={false}/>
                    </div>
                    : <></>
            }

            <div>

                <Modal title={''}
                       maskClosable={false}
                       visible={lookupVisible}
                       width={1000}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => {
                           setLookupVisible(false)
                           setQuick(!quick)
                       }}>
                    <Quick
                        type={quickType}
                        customerId={rowKeys[0]}
                        advanceType={search.type}
                    />
                </Modal>

                {/*转账*/}
                <Modal title={''}
                       maskClosable={false}
                       visible={createTransferVisible}
                       width={800}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => {
                           setCreateTransferVisible(false)
                       }}>
                    <CreateTransfer
                        customerId={rowKeys[0]}
                        type={query.type}
                        onOk={() => {
                            setCreateTransferVisible(false)
                            findCustomerBalances()
                        }}/>
                </Modal>

                <Modal title={''}
                       maskClosable={false}
                       visible={transferListVisible}
                       width={1000}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => {
                           setTransferListVisible(false)
                       }}>
                    <TransferList id={transferId}/>
                </Modal>

                {/*客户来往*/}
                <Modal title={'客户往来详情'}
                       maskClosable={false}
                       visible={lookupCustomerRecordVisible}
                       footer={null} width={1000}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={customerId}/>
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default Balance;