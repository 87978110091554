import React, {useEffect, useRef, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Table, Pagination, DatePicker, Select, Row, Form, Input, InputNumber, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import {Link} from "react-router-dom";
import {Allot} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import moment from "moment";
import BigNumber from "bignumber.js";

const {RangePicker} = DatePicker;
const {Option} = Select;

function AllotIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let searchRef = useRef(null)

    let [subtotal, setSubtotal] = useState({})
    let [tableList, setTableList] = useState([])
    let [companyTable, setCompanyTable] = useState([])
    let initialSearch = {
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdIn: '',
        exampleStatus: Allot.STATUS_IN_PRE_SETTLEMENT,
        statusOuts: [Allot.STATUS_IN_PRE_SETTLEMENT],
        beginReceiveAt: '',
        endReceiveAt: '',
        deliveryMoney: '',
        deliveryCode: '',
        code: '',
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取公司列表 ajax
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/list").then((data) => {
            let arr = data.companies.filter(item => item.id !== common.getUser().company.id)
            setCompanyTable(arr)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCompany, [])

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/list?types=2&types=3", {
            ...pagination,
            companyIdOut: common.getUser().company.id,
            kind: 2,
            brandId: common.getUser().brand.id,
            ...query,
        }).then((data) => {
            // 合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // 小计
            if(data.transfers.length > 0) {
                let obj = {
                    id: '-1',
                    deliveryQuantityTotal: '0.00',
                    deliveryTotal: '0.00',
                    code: '小计',
                }
                data.transfers.forEach((item2) => {
                    // 出库数量
                    obj.deliveryQuantityTotal = new BigNumber(item2.deliveryQuantityTotal).plus(new BigNumber(obj.deliveryQuantityTotal)).toFixed(2)
                    // 出库金额
                    obj.deliveryTotal = new BigNumber(item2.deliveryTotal).plus(new BigNumber(obj.deliveryTotal)).toFixed(2)
                })

                data.transfers.push(obj)
            }

            // 合计
            if(data.subtotal != null) {
                let obj = {
                    id: '-2',
                    deliveryQuantityTotal: data.subtotal.deliveryQuantityTotal,
                    deliveryTotal: data.subtotal.deliveryTotal,
                    code: '合计',
                }

                data.transfers.push(obj)
            }

            setTableList(data.transfers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '调拨单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '调出方',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '接收方',
            dataIndex: 'companyNameIn',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'statusOut',
            width: 100,
            ellipsis: true,
            render: (text) => text in Allot.StatusAlias ? Allot.StatusAlias[text] : text
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '出库数量',
            dataIndex: "deliveryQuantityTotal",
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '出库金额',
            dataIndex: "deliveryTotal",
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '调拨出库人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '调拨日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '接收人',
            dataIndex: 'receiptEmployeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '接收日期',
            dataIndex: 'receiptAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                // 小计/合计
                if(text === undefined) {
                    return ""
                }

                if(text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '结算日期',
            dataIndex: "settlementAtOut",
            width: 120,
            ellipsis: true,
            render: (text) => {
                // 小计/合计
                if(text === undefined) {
                    return ""
                }

                if(text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '操作',
            align: "center",
            fixed: "right",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                // 小计/合计
                if(text === undefined) {
                    return ""
                }
                if(record.id === undefined) {
                    return ""
                }
                if(record.id === "-1") {
                    return ""
                }
                if(record.id === "-2") {
                    return ""
                }

                return (
                    <div className={"action-btns"}>
                        <Button type={'link'}>
                            <Link to={`/wms/allot/settlement/out/${record.id}`}>
                                结算
                            </Link>
                        </Button>
                    </div>)
            }
        }
    ]

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="调拨日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.beginCreatedAt ? moment(search.beginCreatedAt) : null,
                                        search.endCreatedAt ? moment(search.endCreatedAt) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="接收日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.beginReceiveAt ? moment(search.beginReceiveAt) : null,
                                        search.endReceiveAt ? moment(search.endReceiveAt) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginReceiveAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endReceiveAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据状态">
                                <Select value={search.exampleStatus} onChange={(val) => {
                                    if(val === '-1') {
                                        setSearch({
                                            ...search,
                                            exampleStatus: val,
                                            statusOuts: [Allot.STATUS_IN_PRE_SETTLEMENT, Allot.STATUS_IN_SETTLEMENT, Allot.STATUS_IN_PAYMENT]
                                        })

                                        // 未结算
                                    } else if(val === Allot.STATUS_IN_PRE_SETTLEMENT) {
                                        setSearch({
                                            ...search,
                                            exampleStatus: val,
                                            statusOuts: [Allot.STATUS_IN_PRE_SETTLEMENT]
                                        })

                                        // 已结算
                                    } else if(val === Allot.STATUS_IN_PAYMENT) {
                                        setSearch({
                                            ...search,
                                            exampleStatus: val,
                                            statusOuts: [Allot.STATUS_IN_SETTLEMENT, Allot.STATUS_IN_PAYMENT]
                                        })
                                    }
                                }}>
                                    <Option
                                        value={Allot.STATUS_IN_PRE_SETTLEMENT}
                                        key={Allot.STATUS_IN_PRE_SETTLEMENT}>未结算</Option>
                                    <Option
                                        value={Allot.STATUS_IN_PAYMENT}
                                        key={Allot.STATUS_IN_PAYMENT}>已结算</Option>
                                    <Option value={'-1'} key={'-1'}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="接收方">
                                <Select
                                    value={search.companyIdIn}
                                    onChange={(val) => {
                                        setSearch({...search, companyIdIn: val})
                                    }}>
                                    {
                                        companyTable.map((item) =>
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.beginSettlementAtOut ? moment(search.beginSettlementAtOut) : null,
                                        search.endSettlementAtOut ? moment(search.endSettlementAtOut) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginSettlementAtOut: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endSettlementAtOut: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="调拨单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="出库金额">
                                <InputNumber
                                    value={search.deliveryMoney}
                                    style={{width: '100%'}}
                                    precision={2}
                                    min={0.00}
                                    onChange={(val) => {
                                        if(val === null || val === undefined || val === '') {
                                            setSearch({...search, deliveryMoney: ''})
                                        } else {
                                            setSearch({...search, deliveryMoney: val})
                                        }
                                    }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button ref={searchRef} icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={tableList}
                scroll={{x: "100%"}} />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {common.numberFormat(subtotal.deliveryQuantityTotal)}
                        <Divider type="vertical" />
                        金额: {common.numberFormat(subtotal.deliveryTotal)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange} />
                } />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllotIndex)