import React, {useEffect, useRef, useState} from 'react';
import SearchArea from "../../layout/SearchArea";
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, Pagination, Row, Select, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";
import {connect} from "react-redux";
import moment from "moment";
import {Link} from "react-router-dom";
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";

/**
 * 选择一个维修单据
 * 用于收预付款
 */
const {RangePicker} = DatePicker;
const FormItem = Form.Item
const {Option} = Select;
//普通工单
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
// 5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '等待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '订单预结',
    [REPAIR_STATUS_SETTLED]: '订单结算',
    [REPAIR_STATUS_RECEIVED]: '订单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

// 结算中心场景调用
const STATUS_FLAG_ALL = 0
const STATUS_FLAG_NO_PAID = 1
const STATUS_FLAG_PAID = 2

let repairStatusFlagAlias = {
    [STATUS_FLAG_ALL]: [REPAIR_STATUS_PREPAID, REPAIR_STATUS_SETTLED, REPAIR_STATUS_RECEIVED], //全部
    [STATUS_FLAG_NO_PAID]: [REPAIR_STATUS_PREPAID],  //未结算
    [STATUS_FLAG_PAID]: [REPAIR_STATUS_SETTLED, REPAIR_STATUS_RECEIVED], //已结算
}

// 出库场景调用
const DELIVERY_STATUS_FLAG_WAITING = 0
const DELIVERY_STATUS_FLAG_SUPPORTING = 1
const DELIVERY_STATUS_FLAG_NOT_SUPPORTED = 2
let deliveryStatusFlagAlias = {
    [DELIVERY_STATUS_FLAG_WAITING]: [REPAIR_STATUS_WAITING],  // 等待派工
    [DELIVERY_STATUS_FLAG_SUPPORTING]: [REPAIR_STATUS_SUPPORTING],  //在修中
    [DELIVERY_STATUS_FLAG_NOT_SUPPORTED]: [REPAIR_STATUS_WAITING, REPAIR_STATUS_SUPPORTING],  // 等待派工、维修中
}


//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
// const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
// const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
// const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
// const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
// const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

function Repair(props) {

    // 模态框内 使用该组件 一定要传 onOK
    // statuses  状态组
    // onOk 点击确定按钮的回调方法
    // customerId 客户id
    // isMultiple 是否多选
    // scene 来源 （"settlement"结算时调用 , "delivery"快速出库场景调用）
    // initSearch 搜索条件
    let {onOk, customerId, isMultiple, scene = "delivery"} = props

    const [form] = Form.useForm()
    let searchRef = useRef(null)

    let initialSearch = {
        name: '',
        plate: '',
        code: "",
        customerId: customerId || "",
        statuses: scene === "settlement" ? repairStatusFlagAlias[STATUS_FLAG_NO_PAID] : deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_SUPPORTING],
        statusFlag: scene === "settlement" ? STATUS_FLAG_NO_PAID : DELIVERY_STATUS_FLAG_SUPPORTING, //未结算
        repairCategoryIds: [],
    };

    //维修工单列
    const initColumns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '业务时间',
            width: 120,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD  HH:mm')
            }
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '报修日期',
            width: 150,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '送修人',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '预结日期',
            width: 150,
            dataIndex: 'prepayAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '结算日期',
            width: 150,
            dataIndex: 'clearingAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '维修金额',
            width: 80,
            align: 'right',
            ellipsis: true,
            dataIndex: "total",
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'statusName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '里程数',
            width: 80,
            dataIndex: 'mileage',
            ellipsis: true,
            align: "right",
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 70,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },

    ]

    let initSummaries = {"结算金额": "0.00", "客户数": 0, "台数": 0}

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [canDeliveryAtDispatch, setCanDeliveryAtDispatch] = useState() //不派工允许出料
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [repairs, setRepairs] = useState([])
    let [summaries, setSummaries] = useState(initSummaries)
    let [total, setTotal] = useState(0)     //总记录条数
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])
    let [columns, setColumns] = useState(initColumns)
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })


    useEffect(() => {
        scene === "settlement" ? columns = [...initColumns, {
            title: '操作',
            width: 100,
            fixed: "right",
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                if(record.id === "小计" || record.id === "合计") {
                    return
                }
                return (
                    <span className="action-btns">
                        <Link to={`/support/repair/settle/${record.id}`}>结算</Link>
                    </span>
                )
            }
        }] : columns = initColumns
        setColumns([...columns])
    }, [scene])


    // 获取工单列表
    let getRepairs = () => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.consoleLog("params", params)
        common.ajax('get', '/support/repair/list', params).then(res => {
            setTotal(res.pagination.total)
            common.consoleLog("repairList", res)
            if(res.repairs.length > 0) {
                // setRepairs([...res.repairs, res.subtotal, res.total])
                setRepairs([...res.repairs, res.subtotal])
            } else {
                setRepairs([...res.repairs])
            }
            setSummaries(res.summaries || initSummaries)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        // 如果场景为出库且canDeliveryAtDispatch不存在值，则直接返回
        if(scene === "delivery" && canDeliveryAtDispatch === undefined) {
            return
        }
        getRepairs()
    }, [pagination, query])

    // 如果是出库场景获取是否可以不派工出料
    useEffect(() => {
        if(scene === "delivery") {
            common.loadingStart()
            common.ajax('get', '/support/setting/canDeliveryAtDispatch')
                .then(res => {
                    common.consoleLog(res)
                    setCanDeliveryAtDispatch(res)
                })
                .finally(common.loadingStop)
        }
    }, [])

    // 获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                common.consoleLog(data)
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])


    // 设置搜索条件
    // 如果不派工允许出料则设置搜索条件的值 等待派工、维修中
    useEffect(() => {
        if(scene === "delivery") {
            search = {
                ...search,
                statusFlag: canDeliveryAtDispatch ? DELIVERY_STATUS_FLAG_NOT_SUPPORTED : DELIVERY_STATUS_FLAG_SUPPORTING,
                statuses: canDeliveryAtDispatch ? deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_NOT_SUPPORTED] : deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_SUPPORTING],
                customerId: customerId || "",
            }
            setSearch(search)
            setQuery(search)
        }
    }, [canDeliveryAtDispatch])


    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    //确定
    let handleOk = () => {
        if(rows.length === 0) {
            common.toast("请选择维修工单")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    return (
        <div>
            <SearchArea>
                <Form className="ant-advanced-search-form" form={form}>
                    <FormItem hidden={true} style={{display: 'none'}} label={"状态数组"} name='statuses'>
                        <Input hidden={true} />
                    </FormItem>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"工单编号"}>
                                <Input placeholder={"请输入工单编号"} value={search.code}
                                    onChange={e => {
                                        search = {...search, code: e.target.value}
                                        setSearch(search)
                                    }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"送修人员"}>
                                <Input placeholder={"请输入送修人名称"} value={search.name}
                                    onChange={e => {
                                        search = {...search, name: e.target.value}
                                        setSearch(search)
                                    }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号码"} value={search.plate}
                                    onChange={e => {
                                        search = {...search, plate: e.target.value}
                                        setSearch(search)
                                    }}
                                    onPressEnter={() => {
                                        if(searchRef.current) {
                                            searchRef.current.click()
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="单据状态">
                                <Select value={search.statusFlag}
                                    onChange={value => {
                                        search = {...search, statusFlag: value}
                                        setSearch(search)
                                    }}>
                                    {/*结算场景*/}
                                    {
                                        scene === "settlement" ? (
                                            <>
                                                <Option value={STATUS_FLAG_ALL}>全部</Option>
                                                <Option value={STATUS_FLAG_NO_PAID}>未结算</Option>
                                                <Option value={STATUS_FLAG_PAID}>已结算</Option>
                                            </>
                                        ) : null
                                    }
                                    {/*出库场景*/}
                                    {
                                        scene === "delivery" ?
                                            (
                                                <>
                                                    {
                                                        // 如果是可以不派工出料则展示等待派工、维修中, 否则展示在修中
                                                        canDeliveryAtDispatch ?
                                                            <>
                                                                <Option value={DELIVERY_STATUS_FLAG_WAITING}>
                                                                    等待派工
                                                                </Option>
                                                                <Option value={DELIVERY_STATUS_FLAG_SUPPORTING}>
                                                                    在修中
                                                                </Option>
                                                                <Option value={DELIVERY_STATUS_FLAG_NOT_SUPPORTED}>
                                                                    等待派工、维修中
                                                                </Option>
                                                            </>
                                                            :
                                                            <Option value={DELIVERY_STATUS_FLAG_SUPPORTING}>
                                                                在修中
                                                            </Option>
                                                    }
                                                </>
                                            ) : null
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"业务日期"}>
                                <RangePicker
                                    value={[
                                        search.makeAtStart ? moment(search.makeAtStart) : null,
                                        search.makeAtEnd ? moment(search.makeAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        val ? setSearch({
                                            ...search,
                                            makeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            makeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            makeAtStart: "",
                                            makeAtEnd: "",
                                        })
                                    }} />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[
                                        search.clearingAtStart ? moment(search.clearingAtStart) : null,
                                        search.clearingAtEnd ? moment(search.clearingAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            clearingAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            clearingAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            clearingAtStart: "",
                                            clearingAtEnd: "",
                                        })

                                    }} />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工单类型"}>
                                <Select value={search.repairCategoryIds}
                                    placeholder={"请选择工单类型"}
                                    mode="multiple"
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        setSearch({...search, repairCategoryIds: val})
                                    }}>
                                    {repairCategories.map(item =>
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button ref={searchRef} icon={<SearchOutlined />} onClick={() => {
                                    if(scene === "settlement") { // 结算场景
                                        switch(search.statusFlag) {
                                            case STATUS_FLAG_ALL:
                                                search.statuses = repairStatusFlagAlias[STATUS_FLAG_ALL]
                                                break
                                            case STATUS_FLAG_NO_PAID:
                                                search.statuses = repairStatusFlagAlias[STATUS_FLAG_NO_PAID]
                                                break
                                            case STATUS_FLAG_PAID:
                                                search.statuses = repairStatusFlagAlias[STATUS_FLAG_PAID]
                                                break
                                            default:
                                                break
                                        }
                                    } else if(scene === "delivery") { // 出库场景
                                        switch(search.statusFlag) {
                                            case DELIVERY_STATUS_FLAG_WAITING:
                                                search.statuses = deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_WAITING]
                                                break
                                            case DELIVERY_STATUS_FLAG_SUPPORTING:
                                                search.statuses = deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_SUPPORTING]
                                                break
                                            case DELIVERY_STATUS_FLAG_NOT_SUPPORTED:
                                                search.statuses = deliveryStatusFlagAlias[DELIVERY_STATUS_FLAG_NOT_SUPPORTED]
                                                break
                                            default:
                                                break
                                        }
                                    }
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(search)
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if(isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if(item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if(num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                            } else {
                                rowSelection.onChange([record.id], [record], 1)
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={scene === "settlement" ? null : rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={repairs}
            />

            <PageBottom className={"page-bottom-pagination"}
                children={
                    <div>
                        {
                            Object.keys(summaries).map((key, index) => {
                                return (
                                    <React.Fragment key={key}>
                                        <span>{key}：{summaries[key]}</span>
                                        {
                                            // 分割线
                                            index === Object.keys(summaries).length - 1 ? null :
                                                <Divider type="vertical" />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
            </PageBottom>

            <div hidden={scene === "settlement"} className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Repair)