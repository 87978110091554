import React, {useEffect, useState} from 'react';
import {
    CheckCircleOutlined,
    CloseOutlined, PrinterOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Row, Form, Modal, Select, Tooltip, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import condition from "../../../../utils/condition";
import BigNumber from "bignumber.js";
import ArriveForm from "./receiveForm";
import {Basic, Product, Receipt, Warehouse} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import SearchPlanButton from "../../../../components/support/SearchPlanButton/index";
import moment from "moment";

const {RangePicker} = DatePicker

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`id` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [visible, setVisible] = useState(false)
    let [subtotal, setSubtotal] = useState({});

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        receiptCode: "",
        number: "",
        warehouseId: "",
        productId: "",
        supplierName: "",
        deliveryNumber: "",
        businessCode: "",
        kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
        type: Receipt.RECEIPT_TYPE_EMPTY,
        ownerId: common.getUser().company.id,
        brandId: common.getUser().brand.id,
        emptyQuantity: 1,// 默认值:未到货
        exampleName: ''
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [warehouse, setWarehouse] = useState([]) // 仓库
    // let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [orderList, setOrderList] = useState([])

    let rowSelection = {
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.arrivedQuantity === record.receiptQuantity || record.id === '-1' || record.id === '-2'
        })
    }

    const columns = [
        {
            title: '虚入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '送货单号',
            width: 150,
            dataIndex: ['receiptDto', 'deliveryNumber'],
            ellipsis: true
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: ['receiptDto', 'businessCode'],
            ellipsis: true
        },
        {
            title: '客户名称',
            width: 150,
            dataIndex: ['receiptDto', 'orderCustomerName'],
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '销售顾问' : '服务顾问',
            width: 150,
            dataIndex: ['receiptDto', 'orderSaleName'],
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined /></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '虚入库数量',
            width: 100,
            dataIndex: 'receiptQuantity',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text)
        },
        {
            title: '已到货数量',
            width: 100,
            dataIndex: 'arrivedQuantity',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text)
        },
        {
            title: '单位',
            align: 'center',
            width: 150,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '入库单价',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '金额',
            width: 150,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 150,
            align: 'right',
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => text === undefined ? '' : new BigNumber(text).multipliedBy(100).toString() + "%"
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'priceTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '供货商',
            align: 'center',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
            )
        },
        {
            title: '采购人',
            width: 100,
            dataIndex: "employeeName",
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '操作',
            fixed: "right",
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                record.id !== '-1' && record.id !== '-2' &&
                <div className={"action-btns"}>
                    <Button
                        type={"link"}
                        onClick={(e) => {
                            e.stopPropagation() // 阻止事件传播
                            getOrderData(record.id)
                        }}>查看</Button>
                </div>)
        }
    ]

    const columnsPart = [
        {
            title: '订单号',
            dataIndex: ['deliveryDto', 'orderCode'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '订单日期',
            dataIndex: ['deliveryDto', 'orderCreatedAt'],
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'total',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        }
    ]

    let getOrderData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ownerId: common.getUser().company.id,
            kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
            isReceipt: Basic.IS_ANSWER_YES,
            brandIds: [common.getUser().brand.id],
            receiptItemId: params
        }).then((data) => {
            setModalTitle("订单信息")
            setModalNumber(2)
            setVisible2(true)
            data.deliveryItems.forEach((item) => {
                item.total = new BigNumber(item.price).multipliedBy(item.quantity).toNumber()
            })
            setOrderList(data.deliveryItems)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ...pagination,
            ...query,
        }).then((data) => {
            if (data.subtotal === null || data.subtotal.price === null) {
                setSubtotal({})
            } else {
                setSubtotal(data.subtotal)
            }

            if (data.receiptItems.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    arrivedQuantity: 0,
                    priceConst: 0,
                    priceGo: 0,
                    priceTax: 0,
                    receiptDate: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    arrivedQuantity: data.subtotal.day2,
                    priceConst: data.subtotal.price,
                    priceGo: data.subtotal.taxGo,
                    priceTax: new BigNumber(data.subtotal.price).minus(data.subtotal.taxGo).toString(),
                    receiptDate: '合计',
                }
                data.receiptItems.forEach((item) => {
                    item.priceConst = new BigNumber(item.receiptQuantity).multipliedBy(item.price).toString()
                    item.priceGo = new BigNumber(item.priceConst).dividedBy(new BigNumber(item.tax).plus(1)).toFixed(2)
                    item.priceTax = new BigNumber(item.priceConst).minus(item.priceGo).toString()

                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toString()
                    objMy.arrivedQuantity = new BigNumber(item.arrivedQuantity).plus(objMy.arrivedQuantity).toString()
                    // 金额
                    objMy.priceConst = new BigNumber(item.priceConst).plus(objMy.priceConst).toString()
                    // 去税金额
                    objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toString()
                })
                objMy.priceTax = new BigNumber(objMy.priceConst).minus(objMy.priceGo).toString()

                data.receiptItems.push(objMy)
                data.receiptItems.push(objMy2)
            }
            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    let receive = (params) => {
        let arr = {
            ownerId: common.getUser().company.id,
            items: [],
            deliveryNumber: params.deliveryNumber
        }
        params.items.forEach((item) => {
            arr.items.push({
                id: item.id,
                quantity: item.quantity
            })
        })
        common.loadingStart()
        common.ajax("post", "/wms/productGoods/receipt/empty/affirm", arr).then(() => {
            setVisible(false)
            common.toast("到货签收成功")
            setRows([])
            setRowKeys([])
            rowSelection.selectedRowKeys = []
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'receiptDate') {
            sorter.field = 'receiptItem.`receipt_date`'
        } else if (sorter.field === 'arrivedQuantity' || sorter.field === 'receiptQuantity') {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        } else if (sorter.field === 'priceConst') {
            sorter.field = 'receiptItem.`receipt_quantity`*receiptItem.`price`'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    // 打印
    let print = () => {
        if (rows.length === 0) {
            common.toast("请选择入库单");
            return false
        }

        let receiptIds = new Set(rows.map(item => item.receiptId))
        if (receiptIds.size > 1) {
            common.toast("只能选择一个入库单")
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/receiptItem/list", {
                ...query,
                receiptId: rows[0].receiptDto.id,
                brandIds: [common.getUser().brand.id],
                limit: 1000,
                page: 1,
                orderBy: 'receiptItem.`receipt_date` DESC'
            }).then(data => {
                resolve(data.receiptItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = "0.00";// 总金额
            let priceGo = "0.00";// 去税金额
            let priceTax = "0.00";// 税金
            let quantityTotal = "0.00";// 总数量
            printList.forEach((item) => {
                // 去税金额
                item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
                // 税金
                item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax
                item.total = new BigNumber(item.price).multipliedBy(item.receiptQuantity).toFixed(2)
                total = new BigNumber(item.total).plus(total).toFixed(2)
                quantityTotal = new BigNumber(item.receiptQuantity).plus(quantityTotal).toFixed(2)
                priceGo = new BigNumber(item.priceGo).plus(priceGo).toFixed(2)
                priceTax = new BigNumber(item.priceTax).plus(priceTax).toFixed(2)
            })

            let data = {
                "code": "wms_receipt",
                "owner_id": common.getUser().company.id,
                "data": {
                    "companyName": common.getUser().company.name,
                    "code": parseFloat(quantityTotal) < 0 ? "原入库单号: " + printList[0].receiptDto.code : printList[0].receiptDto.code,
                    "total": common.numberCut(total, 2),
                    "quantityTotal": common.numberCut(quantityTotal, 2),
                    "priceGo": common.numberCut(priceGo, 2),
                    "priceTax": common.numberCut(priceTax, 2),
                    "totalUpper": common.moneyToChinese(common.numberCut(total, 2)),
                    "createdAt": printList[0].receiptDate.substring(0, 10),
                    "spec": printList[0].receiptDto.spec,
                    "operator": common.getUser().nickname,
                    "supplierName": printList[0].supplierName,
                    "buyer": printList[0].employeeName,
                    "receiptItems": printList
                }
            }

            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品虚入库管理" : "配件虚入库管理"}>
                <Button disabled={rows.length === 0} icon={<PrinterOutlined />} onClick={print}>打印</Button>
                <Button disabled={rows.length === 0} icon={<CheckCircleOutlined />} type="primary"
                    onClick={() => {
                        if (rows.length === 0) {
                            common.toast('请选择一个入库单')
                            return false
                        }
                        rows.forEach((item) => {
                            item.quantity = null
                        })
                        setVisible(true)
                    }}>到货签收</Button>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setVisible2(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库单号">
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="库别名称">
                                <Select
                                    value={search.warehouseId}
                                    onChange={(val) => {
                                        setSearch({...search, warehouseId: val})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Select.Option value={item.id}
                                                key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="送货单号">
                                <Input value={search.deliveryNumber} onChange={(e) => {
                                    setSearch({...search, deliveryNumber: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="到货状态">
                                <Select
                                    value={search.emptyQuantity}
                                    onChange={(val) => {
                                        setSearch({...search, emptyQuantity: val})
                                    }}>
                                    <Select.Option value={1} key={1}>未到货</Select.Option>
                                    <Select.Option value={2} key={2}>已到货</Select.Option>
                                    <Select.Option value={-1} key={-1}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务单号">
                                <Input value={search.businessCode} onChange={(e) => {
                                    setSearch({...search, businessCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/empty'} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === '-1' || record.id === '-2') {
                                return false
                            }
                            if (record.receiptQuantity - record.arrivedQuantity > 0) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            }
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%', y: '460px'}} />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        订单数量: {common.numberFormat(subtotal.day1)}
                        <Divider type="vertical" />
                        虚入库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical" />
                        已到货数量: {common.numberFormat(subtotal.day2)}
                        <Divider type="vertical" />
                        虚入库金额(含税): {common.numberFormat(subtotal.price)}
                        <Divider type="vertical" />
                        虚入库金额(去税): {common.numberFormat(subtotal.taxGo)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <ArriveForm defaultValue={rows} onOk={receive} action={action} />
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        isHaltProduction={'all'}
                        action={'all'}
                        defaultValue={action}
                        isMultiple={false}
                        onOk={(val) => {
                            setSearch({...search, productId: val.id, exampleName: val.name})
                            setVisible2(false)
                        }} />}
                {modalNumber === 2 &&
                    <Table
                        pagination={false}
                        rowKey="id"
                        columns={columnsPart}
                        dataSource={orderList}
                        scroll={{x: '100%', y: '435px'}} />}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)