import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import moment from 'moment'
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal, Divider, InputNumber} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import LookupCompany from '../../../../components/passport/LookupCompany'
import LookupEmployee from '../../../../components/passport/LookupEmployee'
import common from "../../../../utils/common"
import BigNumber from "bignumber.js";
import ExportButton from "../../../../components/export";
import {SupportSetting} from "../../../../components/support/config";

function ReportProduct() {
    const [form] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const initParams = {
        ownerId: common.getUser().company.id,
        ownerName: common.getUser().company.name,
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [companyListShow, setCompanyListShow] = useState(false)
    const [employeeListType, setEmployeeListType] = useState('')
    const [employeeListShow, setEmployeeListShow] = useState(false)
    let [subtotal, setSubtotal] = useState({})
    const [channelList, setChannelList] = useState([])
    const [category2, setCategory2] = useState([])
    const [productCategory, setProductCategory] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [tableList, setTableList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15,})
    let [accessoryOrderCustomerTypeList, setAccessoryOrderCustomerTypeList] = useState([])
    const [canUpdateData, setCanUpdateData] = useState(false)
    const [dataUpdateRecord, setDataUpdateRecord] = useState({})
    const [dataUpdateVisiable, setDataUpdateVisiable] = useState(false)
    const [dataUpdateTerms, setDataUpdateTerms] = useState([])
    // const typeList = [
    //     {
    //         name: 'customerType',
    //         key: 'public_car',
    //         text: '公户车'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'online',
    //         text: '网约车'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'agent_car',
    //         text: '代办车'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'native_membrane',
    //         text: '自带膜'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'erwang_car',
    //         text: '二网车'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'self_car',
    //         text: '升级客户'
    //     },
    //     {
    //         name: 'customerType',
    //         key: 'not_upgraded',
    //         text: '未升级客户'
    //     },
    // ]

    const initParamsData = () => {
        // 加载集团统计分类
        common.loadingStart();
        common.ajax('get', '/wms/term/list', {
            taxonomy: 'category2',
            status: 1,
            ownerId: common.getUser().company.id
        }).then((res) => {
            if (res && res.terms && res.terms.length) {
                // let _action = res.terms.map(item => {return {name: 'category2', text: item.name, key: item.id}})
                setCategory2(res.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })

        common.loadingStart();
        common.ajax('get', '/crm/term/list', {
            taxonomy: 'channel',
            page: 1,
            limit: 999,
            ownerId: common.getUser().company.id
        }).then((res) => {
            if (res.terms) {
                setChannelList(res.terms)
            }
        }).finally(common.loadingStop)

        // 加载配件管理分类
        common.loadingStart();
        common.ajax('get', '/wms/term/list', {
            taxonomy: 'productCategory',
            status: 1,
            ownerId: common.getUser().company.id
        }).then((res) => {
            if (res && res.terms && res.terms.length) {
                // let _action = res.terms.map(item => {return {name: 'productCategory', text: item.name, key: item.id}})
                setProductCategory(res.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })

        // 部门
        common.loadingStart();
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department',}).then((data) => {
            data = data.filter(item => item.status !== 2)
            setDepartmentList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    const onGetListData = () => {
        let _params = {...searchParams, ...pagination}
        // if (_params.ownerId === undefined || _params.ownerId === null || _params.ownerId === '') {
        //     _params.ownerId = common.getUser().company.id
        // }
        common.loadingStart();
        common.ajax("get", "/report/support/accessoryOrderItemList", _params).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data && data.list) {
                let sum_calc = {
                    id: new Date().getTime(),
                    index: '小计',

                    quantity: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.quantity)
                    }, new BigNumber(0)).toFixed(2),
                    price: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.price)
                    }, new BigNumber(0)).toFixed(2),
                    reduced: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.reduced)
                    }, new BigNumber(0)).toFixed(2),
                    reducedPrice: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.reducedPrice)
                    }, new BigNumber(0)).toFixed(2),
                    reducedTotal: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.reducedTotal)
                    }, new BigNumber(0)).toFixed(2),
                    estimatedCostPrice: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.estimatedCostPrice)
                    }, new BigNumber(0)).toFixed(2),
                    costPrice: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.costPrice)
                    }, new BigNumber(0)).toFixed(2),
                    deliveryQuantity: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.deliveryQuantity)
                    }, new BigNumber(0)).toFixed(2),
                    costTotal: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.costTotal)
                    }, new BigNumber(0)).toFixed(2),
                    profit: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.profit)
                    }, new BigNumber(0)).toFixed(2),
                    rebatePerItem: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.rebatePerItem)
                    }, new BigNumber(0)).toFixed(2),
                    rebateOffset: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.rebateOffset)
                    }, new BigNumber(0)).toFixed(2),
                }
                sum_calc.profitRate = sum_calc.reducedTotal == 0 ? '' : new BigNumber(sum_calc.profit).dividedBy(sum_calc.reducedTotal).times(100).toFixed(2) + '%'
                // profitRate: profit/reducedTotal,
                data.list.push(sum_calc)
                setTableList(data.list)
            } else {
                setTableList([])
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 获取普通权限列表 订正加装订单报表数据
    let getAssignUpdatePlate = () => {
        common.loadingStart();
        common.ajax('get', '/passport/data/findNormalListByUserId', {
            companyId: common.getUser().company.id
        })
            .then(res => {
                res.forEach(item => {
                    if (item === 'updateAccessoryReport') {
                        setCanUpdateData(true)
                    }
                })
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        onGetListData()
    }, [pagination])
    useEffect(() => {
        initParamsData()
        getAssignUpdatePlate()
    }, [])
    const onSearch = (vals) => {
        let _params = {...searchParams}
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals.createdAtRange) {
            if (vals.createdAtRange[0]) {
                vals.createdAtStart = vals.createdAtRange[0].format('YYYY-MM-DD')
            }
            if (vals.createdAtRange[1]) {
                vals.createdAtEnd = vals.createdAtRange[1].format('YYYY-MM-DD')
            }
        }
        if (vals.settlementDateRange) {
            if (vals.settlementDateRange[0]) {
                vals.settlementDateStart = vals.settlementDateRange[0].format('YYYY-MM-DD')
            }
            if (vals.settlementDateRange[1]) {
                vals.settlementDateEnd = vals.settlementDateRange[1].format('YYYY-MM-DD')
            }
        }
        if (vals) {
            let _query = {}
            Object.keys(vals).map(key => {
                if (vals[key] !== undefined) {
                    _query[key] = vals[key]
                }
            })
            _params = {..._params, ..._query}
            setSearchParams(_params)
        }
        setPagination({
            ...pagination,
            page: 1
        })
    }

    let getSupportSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/term/list', {
            taxonomy: params,
            status: SupportSetting.STATUS_YES,
            ownerId: ''
        }).then(data => {
            setAccessoryOrderCustomerTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getSupportSetting(SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE)
    }, [])

    function copyToClipboard(textToCopy) {
        if (document.execCommand('copy')) {
            // 创建textarea
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // 使textarea不在viewport，同时设置不可见
            textArea.style.position = "fixed";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        } else if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy).then(
                (res) => {
                    common.toast("复制成功")
                }).catch((err) => {
                common.toast("复制失败")
            })
        }
    }

    return (
        <>
            <PageTop title="材料出库统计">
                <ExportButton
                    ownerId={searchParams.ownerId}
                    filename={'材料出库统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(searchParams)}
                    type={'support_accessory_order_item'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    className={"ant-advanced-search-form"}
                    form={form} onFinish={onSearch} initialValues={searchParams}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期" className='label-character-4' name="settlementDateRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.settlementDateStart ? moment(searchParams.settlementDateStart) : null, searchParams.settlementDateEnd ? moment(searchParams.settlementDateEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称" className='label-character-4' name="customerName">
                                <Input autoComplete='off' type="text" placeholder={'输入客户名称'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="材质" className='label-character-4' name="termProductId">
                                <Select placeholder="选择材质">
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        productCategory.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="经营主体" className='label-character-4' name="ownerName">
                                <Input readOnly autoComplete="off" placeholder="经营主体"
                                       addonAfter={
                                           <SelectOutlined onClick={() => {
                                               setCompanyListShow(true)
                                           }}/>
                                       }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="商品编号" className='label-character-4' name="number">
                                <Input autoComplete='off' type="text" placeholder={'输入商品编号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号" className='label-character-4' name="plate">
                                <Input autoComplete='off' type="text" placeholder={'输入车牌号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="部位" className='label-character-4' name="termStatisticsId">
                                <Select placeholder="选择部位">
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        category2.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务部门" className='label-character-4' name="saleDepartmentId">
                                <Select placeholder="选择业务部门">
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        departmentList.map(d => {
                                            return <Select.Option value={d.id} key={d.id}>{d.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label="商品名称" className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入商品名称'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单日期" className='label-character-4' name="createdAtRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.createdAtStart ? moment(searchParams.createdAtStart) : null, searchParams.createdAtEnd ? moment(searchParams.createdAtEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据备注" className='label-character-4' name="spec">
                                <Input autoComplete='off' type="text" placeholder={'输入单据备注'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="店端销售" className='label-character-4' name="saleId">
                                <Input readOnly autoComplete="off" placeholder="店端销售"
                                       addonAfter={
                                           <SelectOutlined onClick={() => {
                                               setEmployeeListShow(true);
                                               setEmployeeListType('')
                                           }}/>
                                       }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="单据编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入单据编号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户渠道" className='label-character-4' name="channelId">
                                <Select placeholder="选择客户渠道" onChange={(e) => {
                                    form.setFieldsValue({...form.getFieldsValue(), saleName4s: ''})
                                }}>
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        channelList.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户性质" className='label-character-4' name="customerType">
                                <Select placeholder="选择客户性质">
                                    <Select.Option value="" key={0}>全部</Select.Option>
                                    {
                                        accessoryOrderCustomerTypeList.map(t => {
                                            return <Select.Option value={t.name} key={t.name}>{t.spec}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    form.submit()
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    form.resetFields()
                                    setSearchParams(initParams)
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>
            {/* 数据表格 */}
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        dataIndex: 'index',
                        width: 80,
                        fixed: 'left',
                        render: (text, record, index) => `${record.index != '小计' ? index + 1 : record.index}`,
                    },
                    {
                        title: '结算日期',
                        dataIndex: 'settlementDate',
                        key: 'settlementDate',
                        ellipsis: true,
                        width: 120,
                        render: (text, record, index) => {
                            if (text == undefined || text == null) {
                                return ""
                            }
                            return text.substring(0, 10)
                        }
                    },
                    {
                        title: '单据编号',
                        dataIndex: 'code',
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户名称',
                        dataIndex: 'customerName',
                        key: 'customerName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车牌号',
                        dataIndex: 'plate',
                        key: 'plate',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '品牌',
                        dataIndex: 'seriesName',
                        key: 'seriesName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '车型',
                        dataIndex: 'productName',
                        key: 'productName',
                        ellipsis: true,
                        width: 150,
                    },
                    {
                        title: '车辆分类',
                        dataIndex: 'carTypeName',
                        key: 'carTypeName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '商品编号',
                        dataIndex: 'number',
                        key: 'number',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '商品名称',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '材质',
                        dataIndex: 'termProductName',
                        key: 'termProductName',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '部位',
                        dataIndex: 'termStatisticsName',
                        key: 'termStatisticsName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '订单数量',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        key: 'unit',
                        ellipsis: true,
                        width: 80,
                    },
                    {
                        title: '原销售价',
                        dataIndex: 'price',
                        key: 'price',
                        ellipsis: true,
                        align: "right",
                        width: 80,
                    },
                    {
                        title: '折扣金额',
                        dataIndex: 'reduced',
                        key: 'reduced',
                        ellipsis: true,
                        align: "right",
                        width: 80,
                    },
                    {
                        title: '实际销售单价',
                        dataIndex: 'reducedPrice',
                        key: 'reducedPrice',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '销售金额',
                        dataIndex: 'reducedTotal',
                        key: 'reducedTotal',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '成本单价(预估)',
                        dataIndex: 'estimatedCostPrice',
                        key: 'estimatedCostPrice',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '入库单价',
                        dataIndex: 'costPrice',
                        key: 'costPrice',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '成本出库数量',
                        dataIndex: 'deliveryQuantity',
                        key: 'deliveryQuantity',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '成本金额',
                        dataIndex: 'costTotal',
                        key: 'costTotal',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '毛利',
                        dataIndex: 'profit',
                        key: 'profit',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '毛利率',
                        dataIndex: 'profitRate',
                        key: 'profitRate',
                        ellipsis: true,
                        width: 80,
                        align: "right",
                    },
                    {
                        title: '销售部门',
                        dataIndex: 'saleDepartmentName',
                        key: 'saleDepartmentName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '经营主体',
                        dataIndex: 'ownerName',
                        key: 'ownerName',
                        ellipsis: true,
                        width: 150,
                    },
                    {
                        title: '店端销售',
                        dataIndex: 'saleName',
                        key: 'saleName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '制单人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '单据备注',
                        dataIndex: 'spec',
                        key: 'spec',
                        ellipsis: true,
                        width: 120,
                    },
                    // {
                    //     title: '领料人',
                    //     dataIndex: 'techniciansName',
                    //     key: 'techniciansName',
                    //     ellipsis: true,
                    //     width: 120,
                    // },
                    {
                        title: '施工人',
                        dataIndex: 'techniciansName',
                        key: 'techniciansName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户渠道',
                        dataIndex: 'channelName',
                        key: 'channelName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户性质',
                        dataIndex: 'customerTypeName',
                        key: 'customerTypeName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '订单类型',
                        dataIndex: 'typeName',
                        key: 'typeName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '抵减返利',
                        dataIndex: 'rebateOffset',
                        key: 'rebateOffset',
                        ellipsis: true,
                        align: "right",
                        width: 120,
                    },
                    {
                        title: '单品返利',
                        dataIndex: 'rebatePerItem',
                        key: 'rebatePerItem',
                        align: "right",
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: 'VIN',
                        dataIndex: 'vin',
                        key: 'vin',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        fixed: 'right',
                        width: canUpdateData ? 100 : 0,
                        render:
                            (text, record) => {
                                if (record.index === '小计') {
                                    return ""
                                }
                                return <div className={'action-btns'}>
                                    <Button
                                        type="link"
                                        disabled={!canUpdateData}
                                        onClick={() => {
                                            if (canUpdateData) {
                                                setDataUpdateRecord(record)
                                                formUpdate.setFieldsValue({
                                                    ...record
                                                })
                                                setDataUpdateVisiable(true)
                                                // if (record.channelId) {
                                                //     let channel = channelList.filter(item => item.id == record.channelId)
                                                //     if (channel.length && channel[0].children) {
                                                //         setDataUpdateTerms(channel[0].children)
                                                //     }
                                                // } else {
                                                //     setDataUpdateTerms([])
                                                // }
                                            }
                                        }}>数据订正</Button>
                                </div>
                            }
                    }
                ]}
                dataSource={tableList}
                scroll={{x: '100%'}}
                pagination={false}
            />
            {/* 分页 */}
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            订单数: {subtotal.accessoryOrderId}
                            <Divider type="vertical"/>
                            收入: {(subtotal.reducedTotal === undefined || subtotal.reducedTotal === null) ? "0.00" : common.numberFormat(subtotal.reducedTotal)}
                            <Divider type="vertical"/>
                            成本: {(subtotal.costTotal === undefined || subtotal.costTotal === null) ? "0.00" : common.numberFormat(subtotal.costTotal)}
                            <Divider type="vertical"/>
                            毛利: {(subtotal.profit === undefined || subtotal.profit === null) ? "0.00" : common.numberFormat(subtotal.profit)}
                        </div>
                        :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        onChange={(page, limit) => {
                            setPagination({page, limit})
                        }}
                        onShowSizeChange={(page, limit) => {
                            setPagination({page, limit})
                        }}
                    />
                }>
            </PageBottom>
            {/* 弹框 */}
            <Modal title="选择经营主体"
                   visible={companyListShow}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setCompanyListShow(false)
                   }}>
                <LookupCompany onOk={(val) => {
                    //需要多选，则isMultiple=true
                    setCompanyListShow(false)
                    form.setFieldsValue({'ownerName': val.name, 'ownerId': val.id})
                    setSearchParams({...searchParams, ownerId: val.id})
                }}/>
            </Modal>
            <Modal title={`选择${employeeListType ? employeeListType : '销售人员'}`}
                   visible={employeeListShow}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setEmployeeListShow(false)
                   }}
                   zIndex={1000}>
                <LookupEmployee
                    //如果想单选，传false
                    isMultiple={false}
                    onOk={(val) => {
                        if (employeeListType) {
                            setEmployeeListShow(false)
                            formUpdate.setFieldsValue({[`techniciansId`]: val.id})
                            formUpdate.setFieldsValue({[`techniciansName`]: val.name})
                        } else {
                            setEmployeeListShow(false)
                            form.setFieldsValue({[`saleName${employeeListType}`]: val.name})
                            setSearchParams({...searchParams, [`saleId${employeeListType}`]: val.id})
                        }
                    }}
                />
            </Modal>
            {/* 数据订正 */}
            <Modal
                maskClosable={false}
                visible={dataUpdateVisiable}
                width={500}
                onOk={formUpdate.submit}
                destroyOnClose={true}
                title={"数据订正"}
                onCancel={() => {
                    formUpdate.resetFields()
                    setDataUpdateVisiable(false)
                }}
                zIndex={999}>
                <Form labelCol={{span: 4}} wrapperCol={{span: 20}} labelAlign='left' form={formUpdate}
                      onFinish={vals => {
                          Object.keys(vals).map(key => {
                              if (vals[key] == undefined) {
                                  vals[key] = ''//dataUpdateRecord[key]
                              }
                          })
                          let params = {
                              ...vals,
                              id: dataUpdateRecord.accessoryOrderItemId,
                              ownerId: dataUpdateRecord.ownerId,
                          }
                          common.loadingStart();
                          common.ajax("post", "/support/accessory/order/item/updateReport", params).then(data => {
                              common.consoleLog(data)
                              form.submit()
                              setDataUpdateVisiable(false)

                          }).finally(() => {
                              common.loadingStop()
                          })
                      }}>
                    <Form.Item label={"订单号"}>
                        <div style={{display: 'flex'}}><Input style={{width: 0, flex: 1}} placeholder={'订单号'}
                                                              disabled value={dataUpdateRecord.code}/>
                            <a
                                style={{lineHeight: '26px', marginLeft: '4px'}} onClick={() => {
                                copyToClipboard(dataUpdateRecord.code).then(res => {
                                    common.toast("复制成功")
                                }).catch(err => {
                                    common.toast("复制失败")
                                })
                            }}>复制</a>
                        </div>
                    </Form.Item>
                    <Form.Item label={"材质"} name="termProductId">
                        <Select
                            allowClear={true}
                            placeholder="选择材质">
                            {
                                productCategory.map(c => {
                                    return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {/* <Form.Item label={"部位"} name="termStatisticsId">
                        <Select placeholder="选择部位">
                            <Select.Option value="" key="all">全部</Select.Option>
                            {
                                category2.map(c => {
                                    return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"施工人"} name="techniciansName">
                        <Input readOnly autoComplete="off" placeholder="施工人"
                            addonAfter={
                                <SelectOutlined onClick={() => {
                                    setEmployeeListShow(true);
                                    setEmployeeListType('施工人')
                                }} />
                            }
                        />
                    </Form.Item>
                    <Form.Item label={"施工人"} name="techniciansId" hidden>
                        <Input readOnly autoComplete="off" placeholder="施工人" />
                    </Form.Item>
                    <Form.Item label={"抵减返利"} name="rebateOffset">
                        <InputNumber
                            precision={2}
                            style={{width: "100%"}}
                            min={0}
                            placeholder={'抵减返利'}
                        />
                    </Form.Item>
                    <Form.Item label={"单品返利"} name="rebatePerItem">
                        <InputNumber
                            precision={2}
                            style={{width: "100%"}}
                            min={0}
                            placeholder={'单品返利'}
                        />
                    </Form.Item> */}
                </Form>
            </Modal>
        </>
    )
}

export default ReportProduct