import  {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Basic, Brand, Product, Receipt, Warehouse} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import LookupProduct from "../../../../../../components/wms/LookupProductGoods";
import TypeIndex from "./component/type";
import StatusIndex from "./component/warehouse";
import ReceiptItemIndex from "./component";
import moment from "moment";
import LookupAll from "../../../../../../components/passport/LookupAll";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../../components/export";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index(props) {
    // action 字符串 必填 part(配件) article(精品)
    let {action} = props

    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [number, setNumber] = useState("1");
    let [brand, setBrand] = useState([]);
    let [warehouse, setWarehouse] = useState([]); // 仓库
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(1)

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        checkAtEnd: '',
        productId: "",
        exampleName: "",
        ownerIds: [common.getUser().company.id],
        brandIds: brand.map(item => item.id),
        warehouseId: "",
        supplierId: "",
        number: "",
        spec: "",
        type: "",
        code: "",
        deliveryNumber: "",
        exampleSupplier: "",
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        kind: action,
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    return (
        <>
            <PageTop title={action === Product.KIND_PART ? '配件入库查询' : '精品入库查询'}>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? '精品入库查询.xlsx' : '配件入库查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify(query)}
                    type={'wms_report_part_receipt'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_PART ? "配件编号" : "精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="存放仓库">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="入库类型">
                                <Select
                                    value={search.type}
                                    onChange={(val) => {
                                        // 采购入库 和 采购入库退库
                                        // 虚入库 和 虚入库退库
                                        setSearch({
                                            ...search,
                                            type: val
                                        })
                                    }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_BUY}
                                            key={Receipt.RECEIPT_TYPE_RETURN_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_RETURN_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_RETURN_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                            key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_EMPTY}
                                            key={Receipt.RECEIPT_TYPE_RETURN_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_STOCK}
                                            key={Receipt.RECEIPT_TYPE_RETURN_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                            key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_RETURN_ADJUST}
                                            key={Receipt.RECEIPT_TYPE_RETURN_ADJUST}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_RETURN_ADJUST]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="到货日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.checkAtStart ? moment(search.checkAtStart) : null,
                                        search.checkAtEnd ? moment(search.checkAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_PART ? "配件名称" : "精品名称"}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setModalNumber(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setModalNumber(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"送货单号"}>
                                <Input value={search.deliveryNumber} onChange={(e) => {
                                    setSearch({...search, deliveryNumber: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={search.exampleSupplier}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSupplier === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, supplierId: '', exampleSupplier: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"入库单号"}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"入库备注"}>
                                <Input value={search.spec} onChange={(e) => {
                                    setSearch({...search, spec: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds} onChange={(val) => {
                                    setSearch({...search, brandIds: val})
                                }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            brandIds: brand.map(item => item.id),
                                            page: 1
                                        })
                                    } else {
                                        setQuery({...search, page: 1})
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/receipt'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={number}
                onChange={(key) => {
                    if (search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setNumber(key)
                }}>
                <TabPane tab={"按入库类型统计"} key={"1"}>
                    {number === '1' &&
                    <TypeIndex query={query}/>}
                </TabPane>
                <TabPane tab={"按入库库别统计"} key={"2"}>
                    {number === '2' &&
                    <StatusIndex query={query}/>}
                </TabPane>
                <TabPane tab={"入库单明细"} key={"3"}>
                    {number === '3' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 2 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, supplierId: value.id, exampleSupplier: value.name})
                        setVisible(false)
                    }}
                />}
                {modalNumber === 1 &&
                <LookupProduct
                    isMultiple={true}// 多选
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                    onOk={(arr) => {
                        setSearch({...search, productId: arr.map(item => item.id).join(','), exampleName: arr.map(item => item.name).join(',')})
                        setVisible(false)
                    }}/>}
            </Modal>
        </>
    )
}

export default Index